.fs-container {
  padding-left: 60px;
  width: 230px;
  margin-bottom: 30px;

  .filters {
    .filter-options {
      li {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 4px;
        margin-top: 4px;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        a {
          color: black;

          &:hover {
            color: red;
          }
        }

        &.selected {
          color: red;
          text-decoration: underline;
        }

        &:hover {
          text-decoration: underline;
          font-weight: bold;
        }
      }

      .collection-list {
        max-height: 20vh;
        overflow-y: auto;
      }
    }
  }
}

.size-selection-container {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  z-index: 1;

  .size {
    min-width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 991px) {
  .fs-container {
    display: none;
  }
}
