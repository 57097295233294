.searchResultItemCont {
  cursor: pointer;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0.5rem 0.75rem;

  &:hover {
    .infoContainer {
      .title {
        color: red;
      }
    }
  }

  .imgContainer {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    overflow: hidden;
    background-color: white;
    margin-right: 1rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .infoContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    flex: 1;

    .title {
      font-weight: bold;
      text-wrap: wrap;
    }

    .desc {
      font-weight: normal;
      color: grey;
      text-overflow: ellipsis;
    }
  }
}
