@import "../../../variables.scss";

.blog-list-item {
  margin: 0 0 25px;
  width: 100%;
  position: relative;
  display: flex;

  &:hover {
    .item-details {
      color: $primary;

      transition: all 0.2s ease-in-out;
    }
  }

  .blog-list-item-image {
    display: block;
    background: #d8d8d8;
    width: 130px;

    .item-img-placeholder {
      width: 100%;
      height: 0;
      padding-top: 71.2%;
      position: relative;

      img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center center;
      }
    }
  }

  .item-details {
    margin: 0 0 0 10px;
    width: calc(100% - 130px);
    color: black;
    transition: all 0.2s ease-in-out;
  }

  @media only screen and (min-width: 768px) {
    .blog-list-item-image {
      width: 230px;
      .item-img-placeholder {
      }
    }

    .item-details {
      margin: 0 0 0 20px;
    }
  }

  @media only screen and (min-width: 992px) {
    .headline--4 {
      font-size: 1.375rem;
      letter-spacing: -0.045rem;
      line-height: 1.364;
    }

    width: calc(99.99% / 3);
    padding: 0 7.5px;
    margin: 0 0 70px;
    display: block;

    .blog-list-item-image {
      width: 100%;

      .item-img-placeholder {
        padding-top: 70.31%;
      }
    }

    .item-details {
      margin: 15px 0 0;
      width: 100%;

      .item-meta {
        margin: 15px 0 0;
      }
    }
  }
}
