@import "../../variables.scss";

.blog-slug-page {
  .pg-container {
    margin: 80px 0;

    .container {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      .ghost {
        width: 250px;
        margin-bottom: 4rem;

        path {
          fill: #acacac;
        }
      }

      .info {
        margin-top: 10vh;
        font-weight: bold;

        a {
          color: $primary;
        }
      }
    }
  }
}
