@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@354;475;525;575;645;700&display=swap");

@font-face {
  font-family: SuisseIntlRegular;
  src: local("SuisseIntlRegular"),
    url(./fonts/SuisseIntl-Regular.ttf) format("truetype");
}
@font-face {
  font-family: SuisseIntlMedium;
  src: local("SuisseIntlMedium"),
    url(./fonts/SuisseIntl-Medium.otf) format("otf");
}
@font-face {
  font-family: GesturaReg;
  src: local("GesturaReg"),
    url(./fonts/GesturaText-Regular.woff2) format("woff2");
}

* {
  margin: 0px;
  padding: 0px;
  font-family: SuisseIntlRegular !important;
}
html {
  height: 100%;
  font-family: SuisseIntlRegular !important;
}

body {
  margin: 0;
  font-family: SuisseIntlRegular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
p,
span,
a,
div,
section,
ul,
li {
  font-family: SuisseIntlRegular !important;
}

code {
  font-family: SuisseIntlRegular !important;
}

a {
  text-decoration: none;
}

a span {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
