.size-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Raleway", sans-serif;

  h1 {
    margin: 0px;
    font-size: 26px;
    font-weight: 700;
  }
  p {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 500;
  }
}
.shoe-size-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .size-title {
    margin-top: 50px;
  }
}
