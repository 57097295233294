.fade.modal.show {
  padding: 0 !important;
}

.place-bid-modal {
  border-radius: 0.5rem !important;
  position: fixed;
  background-color: white;
  height: 70vh;
  width: 80vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  .modal-body {
    padding: 0;
    max-height: 100%;
    overflow-y: auto;
  }

  .mobile-header {
    display: none;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 1.25rem;
      font-weight: 600;
      margin: 0;
    }

    .close-modal-btn {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 766px) {
    width: 100%;
    top: 30vh;
    left: 0;
    transform: translate(0%, 0%);
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    .mobile-header {
      display: flex !important;
    }
  }

  .wrapper {
    width: 100%;
    padding-inline: 20%;
    z-index: 1000;

    @media (max-width: 766px) {
      padding: 2rem;
    }
  }

  .header {
    background-color: #d9d9d9;
    width: 100%;
    padding-block: 2rem;
    display: flex;

    background-image: url("../../../public/images/place-bid-modal/graphics.svg");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-size: 50%;

    @media (max-width: 766px) {
      padding-block: 0rem;
      padding-bottom: 6vh;
      background-position-x: 25%;
      background-position-y: bottom;
      background-size: 100%;

      .button-group {
        padding-top: 2rem;
        flex-direction: column;
        justify-content: start !important;
        align-items: start !important;
        gap: 1rem !important;
      }

      .close-modal-btn {
        display: none !important;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 766px) {
        padding-inline: 5%;
      }
    }

    .button-group {
      display: flex;
      justify-content: start;
      gap: 2rem;
      align-items: end;
    }

    .close-modal-btn {
      position: absolute;
      width: 60px;
      aspect-ratio: 1;
      color: white;
      font-size: 40px;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      cursor: pointer;
      z-index: 2000;
      font-weight: 100;

      @media (max-width: 766px) {
        height: 50px;
        top: 5%;
      }
    } // End close-modal-btn

    .btn-express-ship {
      border-radius: 0px !important;
      background-color: white;
      color: black;
      transition: all 0.2s ease-in-out;
      font-weight: 400;

      &:hover {
        background-color: black;
        color: white;
        transition: all 0.2s ease-in-out;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
        transition: all 0.2s ease-in-out;
      }
    } // END btn-express-ship

    .learn-more-link {
      color: black;
      text-decoration: none;
      padding-bottom: 5px;
      border-bottom: 1px solid;
      transition: all 0.2s ease-in-out;

      &:hover {
        transition: all 0.2s ease-in-out;
        border-bottom: 3px solid;
      }
    } // END learn-more-btn

    .bg {
      position: absolute;
      inset: 0;

      img {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 60%;
      }
    } // END bg
  } // END header

  .body {
    position: relative;
    background-color: white;
    padding-block: 2rem;
    padding-bottom: 4rem;

    .wrapper {
      z-index: 1000;
      &* {
        z-index: 1000;
      }
    }

    h1.title {
      margin-bottom: 4rem;
      text-align: center;
    }

    .step {
      margin-bottom: 4rem;
      justify-content: space-between;

      @media (max-width: 766px) {
        &.bid-or-buy {
          flex-direction: column-reverse;
        }
        &.flex-on-everyone {
          flex-direction: column-reverse;
        }
      }

      .step-image {
        position: relative;
        height: 100%;
        width: auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .lowest-ask-tag {
          position: absolute;
          width: 50%;
          height: auto;
          top: 10px;
          left: -25%;
        }
      }

      .content {
        h1 {
          font-size: 55px;
          line-height: 71px;

          &.outline {
            color: white;
            background-color: white;
            text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
              1px 1px 0 #000;
          }
        }
        h4 {
          font-size: 25px;
          line-height: 32px;
        }
        p {
          font-size: 18px;
          line-height: 20px;
        }
      }
    } // END step

    .graphic {
      position: absolute;
      bottom: 0;

      &.plus {
        width: 20%;
        height: auto;
        bottom: 10%;
        left: 10%;
      }

      &.poly {
        width: 20%;
        height: auto;

        @media (max-width: 766px) {
          width: 30%;
        }
      }
    }
  } // END body

  .footer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    padding-bottom: 10%;
    padding-top: 10%;

    @media (max-width: 766px) {
      padding-top: 0%;

      .graphic-container {
        width: 100vw !important;
      }
    }

    .graphic-container {
      position: relative;
      width: 60vw;
      aspect-ratio: 16/9;
      display: flex;
      gap: 4rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .video-frame {
        position: absolute;
        z-index: 1000;
        width: 80%;
        aspect-ratio: 16/9;
        left: 50%;
        transform: translateX(-50%);
        top: -15%;
      }

      img.footer-bg {
        width: 100%;
      }

      img.footer-shades {
        width: 50%;
      }
    } // END graphic-container
  }
}
