@import "../../../variables.scss";

.share-blog {
  padding: 25px 0;

  .sidebar__btn {
    transition: opacity 300ms;
    z-index: 1;
    opacity: 1;
    display: block;
    font-size: 1rem;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background: $primary;
    position: sticky;
    position: -webkit-sticky;
    bottom: 50px;
    left: 0;
    margin: 10px 0 0;
  }

  .sidebar__block-share {
    background: #f9f9f9;
    padding: 11px 17px 15px;

    .sidebar__block-share-title {
      font-size: 0.875rem;
      text-align: center;
      line-height: 1.429;
      font-weight: bold;
    }

    .sidebar__block-share-list {
      display: flex;
      margin: 9px 0 0;

      .sidebar__block-share-items {
        flex: 1;
        display: block;
        height: 20px;

        .sidebar__block-share-link {
          transition: 300ms;
          display: block;
          position: relative;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.2s ease-in-out;

          &:hover {
            transform: scale(1.5);
            transition: all 0.2s ease-in-out;

            svg {
              color: red;
            }
          }
        }
      }
    }
  }

  .sidebar__contain {
    display: none;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    bottom: 50px;
    margin: 0 0 -60px;

    .sidebar__block {
      border: 1px solid #eeeded;
      transition: 500ms cubic-bezier(0.3, 0.475, 0.28, 1.19);
      width: 175px;
      margin: 10px 0 0;
      left: 0;
      position: relative;
    }
  }

  .sidebar__mobile {
    .sidebar__mobile-slider {
      margin: 0 0 30px;
    }

    .sidebar__block-share {
      .sidebar__block-share-list {
        max-width: 250px;
        margin: 9px auto 0;
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    padding: 0 0 0 50px;

    .sidebar__contain {
      .sidebar__block,
      .sidebar__link {
        width: 215px;
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    display: flex;
    position: absolute;
    padding: 0 0 0 20px;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100%;
    width: 0;
    flex-direction: column;
    justify-content: flex-end;

    .sidebar__contain {
      display: block;
    }

    .sidebar__mobile {
      display: none;
    }
  }
}
