.off-canvas {
  position: fixed;
  background-color: black;
  width: 100vw;
  height: 100vh;
  top: -100vh;
  transition: all 0.2s ease-in-out;
  overflow-y: auto;
  z-index: 9999;

  &.show {
    transition: all 0.2s ease-in-out;
    top: 0vh;
  }

  .container {
    @media (max-width: 576px) {
      max-width: 315px;
    }
  }
}
