.settings-page-container {
  //margin-top: 9vh;
  position: relative;
  top: 10%;
  height: fit-content;

  @media screen and (max-width: 830px) {
    margin-top: 0vh;
  }
  .settings-content-container {
    margin-top: 11vh;

    font-family: "Raleway", sans-serif;
    padding: 35px 70px;
    margin-left: 270px;

    @media screen and (max-width: 1100px) {
      margin-left: 10px;
      padding: 0px 35px;
      // margin-top: 6vh;
    }
    @media screen and (max-width: 830px) {
      // margin-top: 5vh;
    }
    @media screen and (max-width: 500px) {
      // margin-top: 5vh;
      margin-left: 0px;
      padding: 0px 0px 0px 35px;
      margin-bottom: 10vh;
    }
    hr {
      color: black;
      opacity: 100;
      height: 0.7px;
    }
    h2 {
      font-weight: 945;
      font-size: 24px;
      margin-bottom: 15px;
      color: rgb(40, 40, 40);
    }
  }
  .menu-button {
    display: none;
    z-index: 100;

    @media screen and (max-width: 1100px) {
      display: block;
      position: fixed;
      top: 65%;
      left: 45%;
    }
    @media screen and (max-width: 800px) {
      top: 65%;
      left: 45%;
    }
    @media screen and (max-width: 500px) {
      left: 37%;
    }
  }
}
