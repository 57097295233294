.searchContainerCont {
  flex-grow: 1;
  flex-shrink: 1;

  .CustomSearchContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border: 1px solid gray;
    border-radius: 2px !important;

    .searchInput {
      padding: 12px;
      flex: 1;
      border: none;
      border-radius: 3px;
      outline: none;
      text-align: left;
      background: transparent;
      // font-weight: bold;
      font-size: 16px;
      color: black !important;
      // text-transform: uppercase;
      &::placeholder {
        text-align: left;
        color: black !important;
        font-size: 16px;

        @media screen and (max-width: 1000px) {
          font-size: 15px;
        }
      }
    }
    i {
      margin: 0px 10px;
    }
    .closeBtn {
      margin: 0px 10px;
      height: 100%;
      border: none !important;
      outline: none !important;
      background: transparent;
    }
  }

  .search-dropdown {
    position: relative !important;
    width: 100%;
    padding: 0px;
    left: auto;
    right: auto;

    .dropdown-menu {
      width: 100% !important;
      overflow-y: hidden !important;

      .dropdown-item {
        padding: 0px;
        color: black !important;

        .searchReultItemCount {
          .infoContainer {
            .title {
              color: black;
            }
          }
        }

        &:hover {
          color: black;
          background: #e9e9e9;
        }
      }
    }
  }

  #basic-nav-dropdown {
    display: none;
  }
}

.nav-menus {
  background-color: whitesmoke;
  width: 400px;
  display: flex;
  position: absolute;
  top: 0;
  transition: 850ms;
  height: 100vh;
  position: fixed;
  flex-direction: column;

  .brand {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    margin-bottom: 20px;
  }

  &.nav-menus-right {
    right: -100%;

    &.active {
      right: 0;
      transition: 350ms;
    }
  }

  &.nav-menus-left {
    background-color: white;
    left: -100%;

    &.active {
      left: 0;
      transition: 350ms;
    }
  }

  .cbtn {
    height: fit-content;
    width: fit-content;
    border: 0;
    background-color: whitesmoke;
    margin: 10px;
    margin-left: 15px;
  }

  h6 {
    margin-top: 15px;
    padding-left: 20px;
    font-size: large;
    color: darkgray;
  }
  .searchBox {
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-width: thin;
    border-color: lightgray;

    .search-container {
      position: relative;
      margin-top: 15px;
      margin-left: 20px;
      width: 85%;
      margin-bottom: 25px;

      input {
        width: 100%;
        padding: 6px;
        border: none;
        padding: 5px 0px 5px 35px;
        border-radius: 3px;
        outline: none;
      }
      input::placeholder {
        text-align: center;
        color: lightgray;
        @media screen and (max-width: 1000px) {
          font-size: 15px;
          text-align: left;
        }
      }
      svg {
        position: absolute;
        z-index: 1;
        transform: translateY(-50%);
        top: 50%;
        left: 10px;
      }
      .closeBtn {
        position: absolute;
        z-index: 1;
        top: 12px;
        right: 10px;
      }
    }
  }

  .nav-menu-items {
    // padding-top: 1vh;
    margin-bottom: 0px !important;
    .nav-text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 15px;
      font-size: 14px;
    }
    .nav-text :hover {
      color: black;
    }
  }
}

@media screen and (max-width: 830px) {
  .nav-menus {
    width: 65vw !important;
  }
}
