.custom-button {
  background-color: rgba(51, 51, 51, 255);
  color: white;
  border: none;
  font-size: 15px;
  font-weight: 645;
  min-width: 110px;
  min-height: 45px;
  border-radius: 4px;
  margin: 10px 0px 10px 0px;
  transition: all 0.4s ease;
  &:hover {
    background-color: rgba(237, 28, 36, 255);
  }
}
.large {
  width: 100%;
  height: 60px;
}
.small {
  min-width: 85px;
  min-height: 45px;
  border-radius: 2px;
  font-size: 12px;
  margin-bottom: 0px;
}
.inverted {
  background-color: transparent;
  color: black;
  border: 2px solid black;
  font-weight: 745;
  &:hover {
    background-color: transparent;

    color: rgba(237, 28, 36, 255);
    border: 2px solid rgba(237, 28, 36, 255);
  }
}
