@import "../../../variables.scss";

.blogs-header-offcanvas {
  padding-top: 170px;

  ul.brands-menu,
  ul.tags-menu {
    display: block;
    list-style: none inside none;
  }

  ul.brands-menu {
    li {
      margin: 0 0 20px;

      a.menu-link {
        opacity: 0.6;
        transition: 300ms;
        position: relative;
        font-size: 2.25rem;
        color: white;
        font-weight: bold;

        &:hover {
          color: white;
          opacity: 1;
          padding: 0 0 0 20px;

          .symbol-icons__list {
            & .symbol-icons__item:nth-of-type(1) {
              animation: fade-in-symbols 300ms linear 300ms 1 normal forwards;
            }

            & .symbol-icons__item:nth-of-type(2) {
              animation: fade-in-symbols 300ms linear 150ms 1 normal forwards;
            }

            & .symbol-icons__item:nth-of-type(3) {
              animation: fade-in-symbols 300ms linear 0ms 1 normal forwards;
            }
          }
        }

        .symbol-icons__list {
          position: absolute;
          right: 100%;
          top: -4px;
          width: 90px;
          font-size: 2.25rem;
          font-weight: 600;
          color: #000;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #fff;
          display: flex;
          justify-content: space-between;

          .symbol-icons__item {
            opacity: 0;
          }
        }
      }
    }
  }

  ul.tags-menu {
    li {
      margin: 0 0 15px;

      a.menu-link {
        opacity: 0.8;
        color: white;
        font-size: 1.5rem;
        font-weight: bold;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: $primary;
          opacity: 1;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }

  .header__blog {
    display: block;
    text-align: center;
    transition: 300ms;
    color: black;
    margin-bottom: 5vh;

    @media only screen and (min-width: 992px) {
      display: none;
    }

    .header__blog-title {
      display: inline-block;
      font-size: 1.5rem;
      border-bottom: 2px solid $primary;
      font-family: SuisseIntlMedium !important;
      margin-bottom: 5px;
    }

    .header__blog-description {
      opacity: 0.5;
      margin: 5px 0 0;
    }
  }

  .all-right-reserved {
    margin-top: 10vh;
    text-align: center;
    color: white;
    opacity: 0.5;
    font-size: 0.8rem;
  }
}

@keyframes fade-in-symbols {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
