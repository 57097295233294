li a {
  font-size: small;
  color: gray;
  margin-bottom: 5px;
}

ul li a:hover {
  color: red;
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

.listULs {
  max-width: 100%;
}

.h6 {
  font-size: 14px;
}
