@import "../../variables.scss";

.mainSliderHome {
  .carousel-item {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media screen and (max-width: 480px) {
  .mainSliderHome {
    margin: 0px -15px -20px -15px !important;

    .img-fluid {
      // height: 120px !important;
      object-fit: contain;
    }
  }
}

.greyNavigation {
  background-color: #f4f3f1;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  margin-top: $navbar-height;
  @media screen and (max-width: 991px) {
    margin-top: $mobile-navbar-height;
  }

  .linkContainber {
    width: 100%;
    height: 100%;
    max-width: 900px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-content: center;
    overflow-x: auto;
    overflow-y: hidden;
    .custBtnsNav {
      cursor: pointer;
      margin: 0px 5px;
      height: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #0f0f0f;
      font-size: 16px;
      font-weight: 700;
      outline: 0px !important;
      outline-color: transparent !important;
      border: 0px !important;
      width: 110px;
    }
  }
}
@mixin responsiveCont {
  .customHomeBanner {
    width: 100%;
    height: 60vh;
    background-color: #4f4a9e;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 20px;
    .textCont {
      height: 100%;
      width: 55%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h1 {
        color: white;
        font-weight: bold;
        margin-bottom: 20px;
        width: 70%;
        font-size: calc(3em + 1vw);
      }
      .customBtnCont {
        width: 70%;
        button {
          background-color: black;
          border: none !important;
          outline: none !important;
          font-size: calc(1em + 0.5vh);
          color: white;
          padding: 10px 20px;
        }
      }
    }
    .imageCont {
      background-image: url("../../assets/homebg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 45%;
    }
  }
}
.home {
  display: flex;
  flex-direction: column;
  @include responsiveCont();
}

.home-mob {
  display: none;
  @include responsiveCont();
}

@media screen and (max-width: 830px) {
  .home {
    // display: none;
    // overflow-y: hidden;
    .customHomeBanner {
      flex-direction: column;
      .imageCont {
        width: 100%;
      }
      .textCont {
        width: 100%;
        h1 {
          font-size: 2em;
        }
        .customBtnCont {
          button {
            font-size: 1em;
          }
        }
      }
    }
  }
  .home-mob {
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    .customHomeBanner {
      flex-direction: column;
      .imageCont {
        width: 100%;
      }
      .textCont {
        width: 100%;
        h1 {
          font-size: 2em;
        }
        .customBtnCont {
          button {
            font-size: 1em;
          }
        }
      }
    }
  }
}
