@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

.edit-offering-container {
  // height: 200px;
  height: fit-content;
  padding-bottom: 10px;
  width: 250px;
  background-color: white;
  z-index: 200;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  @media screen and (max-width: 481px) {
    transform: translate(-100%, -50%);
  }
  .eo-heading {
    margin: 10px;
    margin-top: 5px;
    span {
      font-size: 20px;
      font-weight: 700;
    }
  }
  .eo-expiry {
    margin-left: 10px;
    margin-right: 10px;
    .select-return {
      width: 130px;
      height: 35px;
      font-size: 14px;
      color: rgba(126, 126, 126, 255);
      border: 2px solid gainsboro;
      border-radius: 5px;
      font-weight: 575;
      outline: none;
    }
    .returnable-label {
      margin-top: 7px;
      margin-right: 8px;
      margin-left: 10px;
      text-align: center;
      font-size: 14px;
      color: rgba(126, 126, 126, 255);
      font-weight: 575;
      @media screen and (max-width: 1000px) and (min-width: 800px) {
        width: 110px;
        font-size: 13px;
      }
    }
  }
  .eo-price {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-left: 12px;
    margin-right: 10px;
    .returnable-label {
      margin-top: 7px;
      margin-right: 47px;
      margin-left: 10px;
      text-align: center;
      font-size: 14px;
      color: rgba(126, 126, 126, 255);
      font-weight: 575;
      @media screen and (max-width: 1000px) and (min-width: 800px) {
        width: 110px;
        font-size: 13px;
      }
    }
    .price-form {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      // display: flex;
      h2 {
        font-size: 25px;
        font-weight: 575;
        margin-right: 10px;
        margin-top: 5px;
        @media screen and (max-width: 1000px) {
          margin-top: 7px;
          font-size: 25px;
          font-weight: 575;
        }
      }
      input {
        text-align: right;
        width: 130px;
        height: 35px;
        border: 1px solid rgba(216, 216, 216, 255);
        border-radius: 4px;
        padding: 10px 15px 10px 15px;

        outline: none;
        @include placeholder {
          font-size: 14px;
          color: rgba(126, 126, 126, 255);
          font-weight: 575;
          @media screen and (max-width: 450px) {
            font-size: 12px;
            font-weight: 475;
          }
          @media screen and (max-width: 1000px) {
            //font-size: 12px;
            font-weight: 475;
          }
        }
        padding-right: 15px;
      }
    }
  }
  .eo-buttons {
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: 100%;
    justify-content: space-around;
  }
}
