@import "@splidejs/react-splide/css";
@import "../../../variables.scss";

.buyers-guide {
  margin-bottom: 50px;
  z-index: 1020;
  position: relative;
  padding: 50px 0 50px 3%;

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 816px;
    height: 100%;
    z-index: -1;

    img {
      position: absolute;
      top: 0;
      left: calc(-50% - 50px);
      width: 100%;
      height: 100%;

      @media only screen and (min-width: 1200px) {
        left: -50%;
      }
      @media only screen and (min-width: 992px) {
        left: -55%;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    padding: 140px 0 70px 12%;
    background-image: linear-gradient(180deg, #ffffff 0%, #fcfcfc 100%);
  }

  .custom-wrapper {
    .header {
      text-transform: uppercase;
      padding: 0 0 0 5px;
      letter-spacing: 0.07125rem;
      line-height: 1.25rem;
      font-weight: bold;
    }

    .splide__arrows {
      .splide__arrow {
        background: #fff;
        width: 76px;
        height: 76px;
        border-radius: 100%;
        opacity: 1;
      }

      .splide__arrow--prev {
        @media only screen and (min-width: 992px) {
          left: 12%;
          transform: translate(100%, -30%);
          top: 50%;
        }
      }

      .splide__arrow--next {
        @media only screen and (min-width: 992px) {
          top: 50%;
          transform: translate(0%, -30%);
        }
      }
    }

    .splide__track {
      margin: 20px 0 0;

      .splide__list {
        .splide__slide {
        }
      }
    }
  }
}
