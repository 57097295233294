@import "../../../variables.scss";

.blog.blog-2 {
  position: relative;
  margin-bottom: 20px;
  z-index: 1020;

  // .fp-module__vb-container
  .blog-container {
    // fp-module__vb featured_image_left
    .content-wrapper {
      background: 0 0;
      position: relative;

      &:before {
        content: "";
        height: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        background: #000;
        width: 50%;
      }

      // fp-module__vb-content
      .content {
        // fp-module__vb-image
        .image {
          width: 100%;
          position: relative;
          z-index: 2;
          // fp-module__vb-imageplaceholder
          .placeholder {
            width: 100%;
            height: 0;
            position: relative;
            padding-top: 84.5%;
            img {
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              object-fit: cover;
              object-position: center center;
            }
          }
        }
        // fp-module__vb-info
        .info {
          color: #fff;
          position: relative;
          z-index: 1;
          flex: 1;
          padding: 30px 3% 46px;
          background: #000;

          // headline headline--1
          .headline {
            font-size: 2.375rem;
            letter-spacing: -0.026rem;
            line-height: 1.105;
            font-family: GesturaReg !important;
            font-weight: 400;
          }
          // article-excerpt
          .summary {
            margin: 15px 0 0;
            line-height: 1.625;
            font-weight: 600;
          }
          // article-link article-link--v2
          .link {
            border-radius: 2px;
            position: relative;
            letter-spacing: -0.047rem;
            padding: 15px 23px 16px 25px;
            display: inline-flex;
            align-items: center;
            max-width: 350px;
            color: #000;
            background: #fff;
            transition: all 0.2s ease-in-out;
            margin: 45px 0 0;

            &:hover {
              transition: all 0.2s ease-in-out;
              color: white;
              background: $primary;
            }

            .label {
              flex: 1;
            }

            .plus {
              letter-spacing: 0;
              letter-spacing: 0;
              margin: 0 0 0 37px;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    // .fp-module__vb-container
    .blog-container {
      // fp-module__vb featured_image_left
      .content-wrapper {
        background: #f6f6f6;
        padding: 0 0 83px;

        // fp-module__vb-content
        .content {
          display: flex;
          // fp-module__vb-image
          .image {
            max-width: 817px;
            width: 50%;
            // fp-module__vb-imageplaceholder
            .placeholder {
            }
          }
          // fp-module__vb-info
          .info {
            padding: 30px 3% 31px 50px;

            // headline headline--1
            .headline {
              margin: 25px 0 0;
            }
            // article-excerpt
            .summary {
              margin: 30px 0 0;
            }
            // article-link article-link--v2
            .link {
              // margin: 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 992px) {
    // .fp-module__vb-container
    .blog-container {
      // padding: 0 0 247px;
      background: 0 0;

      // fp-module__vb featured_image_left
      .content-wrapper {
        padding: 0 0 36px;

        &:before {
          height: calc(100% + 47px);
          bottom: unset;
          top: 0;
        }

        // fp-module__vb-content
        .content {
          // fp-module__vb-image
          .image {
            width: 55%;
            // fp-module__vb-imageplaceholder
            .placeholder {
            }
          }
          // fp-module__vb-info
          .info {
            padding: 50px 73px 31px 50px;
            position: relative;
            z-index: 10;

            // headline headline--1
            .headline {
              font-size: 2.375rem;
              letter-spacing: -0.026rem;
              line-height: 1.105;
            }
            // article-excerpt
            .summary {
            }
            // article-link article-link--v2
            .link {
            }
          }
        }
      }
    }
  }
}
