@import "../../variables.scss";

.blog-page {
  padding-top: 125px;
  display: flex;
  flex-direction: column;

  .container {
    max-width: 1295px;
    margin: 0 auto;
    width: 94%;
    position: relative;
  }

  .container--v2 {
    max-width: 770px;
  }

  .headline {
    font-size: 6.25rem;
    letter-spacing: -0.048rem;
  }

  .headline--1 {
    font-family: GesturaReg !important;
    font-size: 2.375rem;
    letter-spacing: -0.026rem;
    line-height: 1.105;
    font-family: gestura reg;
  }

  .headline--3 {
    font-size: 1.375rem;
    letter-spacing: -0.033rem;
    line-height: 1.18;
    font-family: gestura reg;
  }

  .headline--6 {
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.07rem;
  }

  section.hero {
    position: relative;

    .blog-page-image {
      order: 2;
      width: 100%;
      height: 100%;
      z-index: 1;
      position: relative;
      margin: 25px 0 0;

      .image-placeholder {
        width: 100%;
        height: 100%;
        position: relative;

        img {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          object-fit: cover;
          object-position: center center;
        }
      }
    }

    .main {
      padding: 25px 0 0;
      order: 1;
      position: relative;
      z-index: 3;
      display: flex;

      .container {
        .content {
          width: 100%;
          height: 100%;

          .content-block {
            .titles {
              .publish-date {
              }
              .last-updated {
              }
            }
            .author {
            }
          }
          .content-image {
            z-index: 1;
          }
        }
      }
    }

    .intro-wrapper {
      order: 3;
      position: relative;

      .layout-1__intro {
        position: relative;
        z-index: 2;
        color: #fff;
        background: #000;
        padding: 15px 0 23px;

        .headline {
          margin: 0;
          font-size: 1.125rem;
          line-height: 1.778;
          letter-spacing: -0.0533rem;
        }
      }

      .layout-1__intro--uiblock {
        display: none;
      }

      .container {
        max-width: 1295px;
        margin: 0 auto;
        width: 94%;
        position: relative;
      }
    }
  }

  .blog-content {
    position: relative;

    .container {
      .content {
        &:nth-child(1) {
          margin-top: 0 !important;
        }

        p {
          margin: 32px 0;
          font-size: 0.9375rem;
          line-height: 2;
          line-height: normal;
        }

        a {
          display: inline-block;
          color: $primary;
          position: relative;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 15px 0;
        }

        h1 {
          font-size: 3.75rem;
          letter-spacing: -0.04rem;
        }

        h2 {
          font-size: 1.375rem;
          letter-spacing: -0.033rem;
          line-height: 1.18;
        }

        h3 {
          font-size: 2.375rem;
          letter-spacing: -0.026rem;
          line-height: 1.125;
        }

        h4 {
          font-size: 1.125rem;
          letter-spacing: -0.042rem;
          line-height: 1.333;
        }

        h5 {
          font-size: 1rem;
          letter-spacing: -0.062rem;
        }

        h6 {
          font-size: 0.75rem;
          text-transform: uppercase;
          letter-spacing: 0.07rem;
        }

        img {
          margin: 50px auto;
          width: auto;
          max-width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    section.hero {
      display: flex;
      flex-direction: column;

      .blog-page-image {
        .image-placeholder {
          height: 0;
          padding-top: 90%;
        }
      }
    }
  }

  @media only screen and (min-width: 992px) {
    display: block;

    .headline--1 {
      margin: 12px 0 0;
      font-size: 2.375rem;
    }

    .headline--3 {
      font-size: 1.75rem;
      letter-spacing: -0.053rem;
      line-height: 1.364;
    }

    section.hero {
      position: relative;

      .blog-page-image {
        order: unset;
        position: absolute;
        width: 50%;
        height: 100%;
        margin: 0;
        right: 0;

        .image-placeholder {
          img {
          }
        }
      }

      .main {
        position: relative;
        display: block;
        padding: 0;

        .container {
          .content {
            display: flex;
            align-items: flex-start;

            .content-block {
              width: 50%;
              padding: 54px 27px 175px 0;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .titles {
                .publish-date {
                }
                .last-updated {
                }
              }
              .author {
                margin: 75px 0 0;
              }
            }
            .content-image {
              display: block;
              position: relative;
              max-width: none;
              order: unset;
              width: 50%;
              margin: 0;
              right: 0;
            }
          }
        }
      }

      .intro-wrapper {
        .layout-1__intro {
          display: block;
          order: unset;
          position: absolute;
          width: 100%;
          bottom: 63.3%;
          padding: 30px 0 40px;
          background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 1) 62.5%,
            rgba(0, 0, 0, 0) 62.5%
          );

          .headline {
            max-width: 59.5%;
            font-size: 1.75rem;
            letter-spacing: -0.053rem;
            line-height: 1.5;
          }
        }

        .layout-1__intro--uiblock {
          display: block;
          visibility: hidden;
          position: relative;
          bottom: 0;
        }
      }
    }

    .blog-content {
      .container {
        .content {
          padding: 35px 0px;

          p {
            font-size: 1.125rem;
          }

          h2 {
            font-size: 1.75rem;
            letter-spacing: -0.053rem;
            line-height: 1.364;
          }

          h3 {
            font-size: 2.625rem;
            letter-spacing: -0.031rem;
          }

          h4 {
            font-size: 1.375rem;
            letter-spacing: -0.045rem;
            line-height: 1.364;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .headline--1 {
      font-size: 3.75rem;
    }

    section.hero {
      position: relative;

      .blog-page-image {
        .image-placeholder {
          img {
          }
        }
      }

      .main {
        .container {
          .content {
            .content-block {
              min-height: calc(543px + 48px);
            }
            .content-image {
              .image-placeholder {
              }
            }
          }
        }
      }
    }
  }
}
