@import "../../variables.scss";

.primary-container {
  min-height: 100vh;
  margin-top: $navbar-height;
  display: flex;
  flex-direction: column;
  // border: 10px solid green;

  .items-container {
    display: flex;
    justify-content: center;
    min-height: 100vh;

    .shoe-card-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      flex: 1;
      padding-inline: 30px;
    }

    .empty-result {
      display: flex;
      justify-content: center;
      padding: 60px 20px;
      text-align: center;
    }
  }
}

@media (max-width: 991px) {
  .primary-container {
    margin-top: $mobile-navbar-height;
    // .banner-filter-container {
    //   display: none;
    // }
    .items-container {
      .shoe-card-container {
        padding-inline: 0px;
      }
    }

    .empty-result {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 60px 20px;
    }
  }
}
