.blog-products {
  // background-color: red;

  .product-item {
    width: 100%;
    height: auto;

    .image-container {
      width: 100%;
      height: auto;
    }

    .title-link {
      h3 {
        margin: 0;
        font-family: SuisseIntlMedium !important;
      }
    }
  }
}
