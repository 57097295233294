@import "../../../variables.scss";

//fp-module
.blog.blog-1 {
  position: relative;
  margin-bottom: 20px;
  z-index: 1010;

  // fp-module__awbi-container
  .blog-container {
    //fp-module__awbi
    .content-wrapper {
      //container
      .container {
        max-width: 1295px;
        margin: 0 auto;
        width: 94%;
        position: relative;

        // fp-module__awbi-block featured_image_left
        .block {
          display: flex;
          align-items: flex-start;
          max-width: 1245px;
          margin: 0 0 0 auto;

          // fp-module__awbi-image
          .block-image {
            width: 100%;
            margin: 73px 0 0;
            position: relative;
            z-index: 2;

            .image {
              width: 100%;
              height: 0;
              padding-top: 84.41%;
              position: relative;
              right: 0;

              img {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                object-position: center center;
              }
            }
          }

          // fp-module__awbi-content
          .block-content {
            flex: 1;

            // fp-module__awbi-content-text
            .block-content-text {
              display: none;
              background: #fff;
              padding: 30px 25px 75px 70px;
              position: relative;
              right: 30px;
              z-index: 1;

              // headline headline--1
              .headline {
                font-family: GesturaReg !important;
                font-weight: 400;
              }
              // article-excerpt
              .summary {
                margin: 15px 0 0;
                line-height: 1.625;
                font-weight: 600;
              }
            }
            // fp-module__awbi-linkholder
            .block-content-link {
              position: relative;
              display: none;

              // article-link
              .blog-link {
                position: absolute;
                top: -30px;
                left: -120px;
                z-index: 3;
                border-radius: 2px;
                letter-spacing: -0.047rem;
                color: #fff;
                background: #000;
                padding: 15px 23px 16px 25px;
                display: inline-flex;
                align-items: center;
                max-width: 350px;
                transition: all 0.2s ease-in-out;

                &:hover {
                  transition: all 0.2s ease-in-out;
                  color: white;
                  background: $primary;
                }

                .label {
                  flex: 1;
                }

                .plus {
                  letter-spacing: 0;
                  letter-spacing: 0;
                  margin: 0 0 0 37px;
                }
              }
            }
          }
        }
      }
    }
  }

  // fp-module__awbi-content-text fp-module__awbi-content-text--mobile
  .content-mobile {
    display: block;
    padding: 0;
    right: 0;
    width: 94%;
    margin: 26px auto 0;
    z-index: 1;
    position: relative;
    background: #fff;
    .headline {
      font-size: 2.375rem;
      letter-spacing: -0.026rem;
      line-height: 1.105;
      font-family: GesturaReg !important;
    }
    .summary {
      margin: 15px 0 0;
      line-height: 1.625;
      font-weight: 600;
    }
    .blog-link {
      margin: 30px 0 0;
      border-radius: 2px;
      position: relative;
      letter-spacing: -0.047rem;
      color: #fff;
      background: #000;
      padding: 15px 23px 16px 25px;
      display: inline-flex;
      align-items: center;
      max-width: 350px;
      margin: 45px 0 0;

      .label {
        flex: 1;
      }
      .plus {
        letter-spacing: 0;
        letter-spacing: 0;
        margin: 0 0 0 37px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    // fp-module__awbi-container
    .blog-container {
      //fp-module__awbi
      .content-wrapper {
        padding: 50px 0 125px;
        //container
        .container {
          // fp-module__awbi-block featured_image_left
          .block {
            // fp-module__awbi-image
            .block-image {
              max-width: 725px;
              width: 50%;

              .image {
                right: 0;
              }
            }

            // fp-module__awbi-content
            .block-content {
              // fp-module__awbi-content-text
              .block-content-text {
                display: block;

                // headline headline--1
                .headline {
                }
                // article-excerpt
                .summary {
                  margin: 30px 0 0;
                }
              }
              // fp-module__awbi-linkholder
              .block-content-link {
                display: block;

                // article-link
                .blog-link {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }

    .content-mobile {
      display: none;
    }
  }

  @media only screen and (min-width: 992px) {
    // fp-module__awbi-container
    .blog-container {
      // padding: 0px 0 125px;

      //fp-module__awbi
      .content-wrapper {
        // padding: 100px 0 250px;

        //container
        .container {
          // fp-module__awbi-block featured_image_left
          .block {
            // fp-module__awbi-image
            .block-image {
              width: 59%;

              .image {
                right: 0;
              }
            }

            // fp-module__awbi-content
            .block-content {
              // fp-module__awbi-content-text
              .block-content-text {
                // headline headline--1
                .headline {
                  font-size: 2.375rem;
                  letter-spacing: -0.026rem;
                  line-height: 1.105;
                }
                // article-excerpt
                .summary {
                }
              }
              // fp-module__awbi-linkholder
              .block-content-link {
                // article-link
                .blog-link {
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    // fp-module__awbi-container
    .blog-container {
      //fp-module__awbi
      .content-wrapper {
        //container
        .container {
          // fp-module__awbi-block featured_image_left
          .block {
            // fp-module__awbi-image
            .block-image {
              .image {
              }
            }

            // fp-module__awbi-content
            .block-content {
              // fp-module__awbi-content-text
              .block-content-text {
                // headline headline--1
                .headline {
                  font-size: 3.75rem;
                  letter-spacing: -0.04rem;
                  line-height: 1.083;
                }
                // article-excerpt
                .summary {
                }
              }
              // fp-module__awbi-linkholder
              .block-content-link {
              }
            }
          }
        }
      }
    }
  }
}
