@import "../../variables.scss";

.infoQuestionBtn {
  font-size: 13px;
  color: white;
  background: black;
  border-radius: 10px;
  height: 20px;
  width: 20px;
  font-weight: 600;
  position: relative !important;
  top: -2px;
}

.productDescriptionTextLimiter {
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  height: 144px;
}

.productEndBox {
  background-color: #393939;
  padding: 15px;
  display: flex;
  color: white;
}

.productEndBoxHeading {
  font-size: 14px !important;
}

.productEndBoxDescription {
  font-size: 14px !important;
}

.productEndBoxLearnMore {
  font-size: 13px !important;
  border-bottom: 2px solid white;
  width: 80px;
}

.mobileConTextContainer1 {
  padding-right: 0px !important;
}

.mobileConTextContainer2 {
  padding-left: 5px !important;
}

.mobileConText {
  font-weight: 700;
  width: 100%;
  padding: 5px;
  background-color: #f4f3f1;
  font-size: 14px !important;
}

.placeBidBtn,
.buyNowBtn {
  border-radius: 0px !important;
  width: 48% !important;
  font-size: 13px;
  font-weight: 900;
  padding: 10px !important;
}

.fM2 {
  font-size: 15px;
  font-weight: 600;
}

.fM {
  font-size: 15px;
}

.bold900 {
  font-weight: 900 !important;
}

.hr {
  border-top: 0.01px solid #f7f7f7 !important;
  height: 0.1px !important;
  background: lightgray;
}

.hrThick {
  border-top: 10px solid #f7f7f7 !important;
  height: 10px !important;
  background: lightgray;
}

.productVerify h3 {
  font-size: 24px;
}

.productVerify p {
  font-size: 13px;
}

.noExtraMargins {
  margin: 0px 0px 0px -7px !important;
}

.showOnMobile {
  display: none;
}

@media screen and (max-width: 991px) {
  .showOnMobile {
    display: block !important;
  }
}
@media screen and (max-width: 480px) {
  .showOnMobileFlex {
    display: flex !important;
  }

  .product-page-container {
    .productName {
      margin-top: 5px !important;

      font-size: 19px !important;
      font-weight: 600 !important;
    }
    .productsSubName {
      margin-top: -5px !important;

      font-size: 18px !important;
      font-weight: 600 !important;
      color: #5c5c5c !important;
    }
    .smallBtnsContainer button {
      width: auto !important;
    }
    .CustomcardDiv {
      margin: 0px 0px 0px 10px !important;
    }
    .slick-slide {
      width: auto !important;
    }
    .slick-arrow {
      display: none !important;
    }
  }
}

.size-chart-btn {
  &:hover {
    color: white !important;
  }
}

@mixin butnStyle($bg, $color) {
  background-color: $bg;
  color: $color;
  border: 1px solid $color;
  &:hover {
    background-color: $color;
    color: $bg;
  }
}

.pdValues {
  text-align: right !important;
}

.smallBtnsContainer {
  width: 100%;
  button {
    width: 70px;
  }
}
.product-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  // margin-top: $navbar-height;
  margin-top: $mobile-navbar-height;

  @media screen and (min-width: 1050px) {
    .prod-top-contain {
      width: 100%;
      padding-left: 60px;
      padding-right: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .conText {
        font-weight: 600;
        padding-top: 10px;

        font-weight: 500;
      }
      .blink {
        animation: blinker 1.5s linear infinite;
        @keyframes blinker {
          50% {
            opacity: 0;
          }
        }
      }
    }

    .mob-prod-container {
      display: none;
    }

    .prod-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 70vh;
      padding-right: 60px;
      padding-left: 60px;
      margin-bottom: 20px;
      .prod-info-container {
        display: flex;
        flex-direction: column;
        font-size: large;
        font-weight: 300;
        align-self: center;
        width: 20%;
        span {
          margin: 10px;
        }
      }
      .prod-img-container {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 35px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // opacity: 0.8;
        // align-items: center;
        width: 58%;
        .prod-img-text-container {
          letter-spacing: 0;
          font-weight: 700;
        }
        .swiper-scrollbar {
          height: 3px;
        }
        .swiper-scrollbar-drag {
          background: black;
          height: 100%;
        }
        .shoeImg {
          .swiper-container {
            height: 90%;
            width: 100%;
          }
          .swiper-slide {
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
      .prod-size-container {
        width: 18%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        span {
          padding-top: 15px;
          font-weight: 600;
          min-width: fit-content;
        }
      }
      @media screen and (max-width: 1050px) {
        display: none;
      }
    }
    .prod-page-suggest {
      width: 100%;
      justify-content: center;
      align-items: center;
      align-self: center;
    }
    .prod-text {
      width: 50%;
      justify-content: center;
      align-items: center;
      align-self: center;
      text-align: center;
      margin-top: 10px;
    }

    .customFont {
      font-weight: 350;
    }
  }

  @media screen and (min-width: 0px) and (max-width: 481px) {
    .sizeSlctbtn {
      width: 100%;
      height: 40px;
      margin: 10px auto;
      border: 1px solid lightgrey !important;
      background-color: transparent !important;
      .fill_size {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        font-size: 14px;
        padding: 0px 10px;
        font-weight: 900;

        span {
          font-weight: 900;
        }
      }
    }

    margin-top: 7vh;
    .prod-top-contain {
      display: none;
    }
    .prod-container {
      display: none;
    }
    .prod-text {
      justify-content: center;
      align-items: center;
      align-self: center;
      text-align: left;
      margin-top: 10px;
      padding-left: 25px;
      padding-right: 25px;
    }

    .mob-prod-container {
      .prod-img-container {
        margin-top: 150px;
        font-size: 35px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // opacity: 0.8;
        // align-items: center;
        width: 100%;
        min-height: 45vh;
        margin-bottom: 13px;
        .conText {
          font-size: small;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .blink {
          animation: blinker 1.5s linear infinite;
          @keyframes blinker {
            50% {
              opacity: 0;
            }
          }
        }
        .prod-img-text-container {
          letter-spacing: 0;
          font-weight: 700;
          font-size: 40px;
        }
        .swiper-scrollbar {
          height: 3px;
          margin-left: 5%;
          margin-right: 5%;
          width: 90%;
          left: 0;
        }
        .swiper-scrollbar-drag {
          background: black;
          height: 100%;
        }
        .shoeImg {
          .swiper-container {
            height: 90%;
            width: 100%;
          }
          .swiper-slide {
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
          }
        }
        // img {
        //   max-width: 100%;
        //   max-height: 100%;
        // }
      }

      .mob-prod-top-contain {
        width: 100%;
        padding: 15px;
        position: sticky;
        top: 7vh;
        background-color: white;
        z-index: 1;
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        //for now
        .sizeSlctbtn {
          width: 100%;
          height: 40px;
          margin: 10px auto;
          border: 1px solid lightgrey !important;
          background-color: transparent !important;
          .fill_size {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            font-size: 12px;
            padding: 0px 10px;
            span {
              font-weight: 600;
            }
          }
        }
        .btnContainers {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          .customBtnn {
            height: 100%;
            width: 48%;
            &.cstoffer {
              @include butnStyle(red, white);
            }
            &.buynow {
              @include butnStyle(white, black);
            }
          }
        }
      }

      .mob-prod-img-container {
        display: flex;
        width: 100%;
        height: 40vh;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: scaleX(-1);
        filter: FlipH;
      }

      .prod-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-weight: 400;
        align-self: center;
        .prod-price-container {
          font-size: 1.5rem;
        }
      }
    }
    .prod-page-suggest {
      width: 100%;
      justify-content: center;
      align-items: center;
      align-self: center;

      .suggestions {
        margin: 0px;
        .sug-txt {
          margin: 0px;
          margin-top: 20px;
          padding-left: 22px;
          font-size: larger;
        }
      }

      .card {
      }
    }

    .customFont {
      font-weight: 100;
    }
  }

  .product-filter {
    position: sticky;
    top: $navbar-height;
    background: white;
    z-index: 999;
    width: 100vw;
    max-width: 100vw;
    margin: 0;
    border: none;
    padding: 10px 10px 0px 10px;

    @media screen and (max-width: 991px) {
      top: $mobile-navbar-height;
    }

    .size-selector-container {
      position: relative;
    }

    .button-container {
      display: flex;
      justify-content: space-between;
    }

    .buy-now-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }

    @media screen and (min-width: 991px) {
      display: none;
    }
  }
}
// new styles wala done
.responsiveheight {
  height: auto;
}
@media screen and (max-width: 991px) {
  .responsiveheight {
    height: 100%;
  }
}

.conText {
  font-weight: 600;
  padding-top: 10px;
  font-weight: 500;
  width: fit-content;
  padding: 0px 10px;
  background-color: #f4f3f1;
  &.blink {
    animation: blinker 1.5s linear infinite;
    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
  }
}

.borderedDiv {
  border-radius: 0.25rem;
  border: 1px solid lightgrey;
}
.removeBorder {
  border: 0px !important;
}
.btn {
  &.smallBtns {
    padding: 0.2rem;
    font-size: 10px;
  }
}
@media screen and (max-width: 991px) {
  .btn {
    &.smallBtns {
      padding: 0.3rem 0.75rem;
      font-size: 0.9rem;
    }
  }
}
hr {
  flex-shrink: 1 !important;
  margin: 0px;
}
.customerBuyClass {
  height: 60px;
  background-color: #f4f3f1;
  .col-2 {
    img {
      height: 70%;
      object-fit: contain;
    }
  }
  .col-10 {
    font-size: 1em;
    font-weight: 600;
  }
}
.authenticImg {
  width: 90px;
  height: 90px;
  object-fit: contain;
}
.fill_size {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-size: 18px;
  padding: 0px 10px;
  span {
    font-weight: 600;
  }
}

.p13 {
  padding: 13px !important;
}
