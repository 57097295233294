.blog-slide {
  box-shadow: 0 6px 17px 0 transparent;
  width: 100%;
  aspect-ratio: 1;
  display: block;
  position: relative;
  border-radius: 2px;
  overflow: hidden;

  img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .slide-info {
    transition: 300ms;
    display: flex;
    z-index: 2;
    align-items: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    padding: 5px;

    .info-block {
      background: #fff;
      padding: 5px 10px 8px;

      .headline {
        font-size: 1.125rem;
        letter-spacing: -0.042rem;
        line-height: 1.333;
        font-family: gestura reg;
      }

      .slide-info-meta {
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 0.05375rem;
        line-height: 1.667;
        margin: 0;
      }
    }
  }
}
