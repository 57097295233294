@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
.price-calculator {
  margin-top: 10px;
  padding: 5px;

  @media screen and (max-width: 450px) {
    padding: 10px;
  }
  .price-form {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    display: flex;
    h2 {
      font-size: 25px;
      font-weight: 575;
      margin-right: 10px;
      margin-top: 5px;
      @media screen and (max-width: 1000px) {
        margin-top: 7px;
        font-size: 25px;
        font-weight: 575;
      }
    }
    input {
      text-align: right;
      width: 100%;
      border: 1px solid rgba(216, 216, 216, 255);
      border-radius: 4px;
      padding: 10px 15px 10px 15px;

      outline: none;
      @include placeholder {
        font-size: 14px;
        color: rgba(126, 126, 126, 255);
        font-weight: 575;
        @media screen and (max-width: 450px) {
          font-size: 12px;
          font-weight: 475;
        }
        @media screen and (max-width: 1000px) {
          //font-size: 12px;
          font-weight: 475;
        }
      }
      padding-right: 15px;
    }
  }
  .mini {
    margin-top: 5px;
    text-align: center;
    font-size: 13px;
    color: rgba(126, 126, 126, 255);
    font-weight: 575;
    @media screen and (max-width: 1000px) {
      font-size: 12px;
    }
  }
  .price-table {
    .price-row {
      border-bottom: 1px solid #c7c7c7;
      font-size: 14px;
      margin: 0px;
      padding: 0px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      p {
        font-size: 14px;
        font-weight: 575;
        margin: 7px 10px;
        letter-spacing: 0.5px;
        @media screen and (max-width: 800px) {
          font-size: 14px;
          font-weight: 575;
        }
        @media screen and (max-width: 450px) {
          margin-bottom: 7px;
          font-size: 12px;
        }
        @media screen and (max-width: 1000px) and (min-width: 800px) {
          margin-top: 4px;
          margin-bottom: 7px;
          font-size: 11px;
          font-weight: 575;
          letter-spacing: 1px;
        }
      }
    }
  }
}
.returnable-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 0px;
  .check-button {
    margin-left: 14px;
    margin-right: 8px;
    height: 12px;
    &:checked {
    }
  }
  .select-return {
    width: 130px;
    height: 35px;
    font-size: 14px;
    color: rgba(126, 126, 126, 255);
    border: 2px solid gainsboro;
    border-radius: 5px;
    font-weight: 575;
    outline: none;

    @media screen and (max-width: 1000px) {
      width: 110px;
    }
  }
  .returnable-label {
    margin-top: 7px;
    margin-right: 8px;
    margin-left: 10px;
    text-align: center;
    font-size: 14px;
    color: rgba(126, 126, 126, 255);
    font-weight: 575;
    @media screen and (max-width: 1000px) and (min-width: 800px) {
      width: 110px;
      font-size: 13px;
    }
  }

  .question {
    margin: 0px 2px;
    font-size: 12.5px;
    position: relative;
    color: rgba(126, 126, 126, 255);
    &:hover {
      color: rgba(96, 96, 96, 255);
    }
  }
}
