.favourites-page-container {
  font-family: "Raleway", sans-serif;
  // margin-top: 9vh;
  position: relative;
  top: 10%;
  @media screen and (max-width: 830px) {
    margin-top: 0vh;
  }

  .favourites-content-container {
    margin-top: 11vh;
    margin-left: 270px;
    padding: 30px 75px;

    @media screen and (max-width: 1100px) {
      margin-left: 10px;
      padding: 0px 35px;
    }
    @media screen and (max-width: 800px) {
      margin-top: 0vh;
    }
    @media screen and (max-width: 500px) {
      margin-left: 0px;
      padding: 0px 15px;
    }
    .favourites-header {
      width: 70vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      @media screen and (max-width: 500px) {
        width: 92vw;
      }
      h2 {
        font-family: "Roboto", sans-serif;
        font-weight: 945;
        font-size: 24px;
        margin-top: 20px;
        color: rgb(40, 40, 40);
      }
    }
  }
  .menu-button {
    display: none;
    z-index: 100;

    @media screen and (max-width: 1100px) {
      display: block;
      position: fixed;
      top: 65%;
      left: 45%;
    }
    @media screen and (max-width: 800px) {
      top: 65%;
      left: 45%;
    }
    @media screen and (max-width: 500px) {
      left: 37%;
    }
  }
}
