@import "../../../variables.scss";

.blogs-search-results {
  background-color: white !important;
  padding-top: 170px;

  .search-group-list {
    .header__search-group-item {
      padding: 10px 22px;
      display: flex;
      flex-direction: row;
      align-items: center;
      transition: all 0.2s ease-in-out;
      border-radius: 4px;

      &:hover {
        .header__search-group-item-title {
          color: $primary;
        }
      }

      img.header__search-group-item-img {
        width: 110px;
        margin-right: 18px;
        height: 110px;
        border-radius: 4px;
        object-fit: cover;
        object-position: center center;
      }

      div {
        flex: 1;

        .header__search-group-item-top-line {
          transition: 300ms;
          opacity: 0.5;
          color: #000;
          display: block;
        }

        .header__search-group-item-title {
          transition: 300ms;
          font-size: 1.25rem;
          letter-spacing: -0.078rem;
          line-height: 1.25;
          display: block;
          color: black;
        }

        .header__search-group-item-bottom-line {
          transition: 300ms;
          text-transform: uppercase;
          display: block;
          opacity: 0.5;
        }
      }
    }
  }

  @media only screen and (min-width: 992px) {
    .search-group-list {
      .header__search-group-item {
        div {
          .header__search-group-item-title {
            font-size: 1.75rem;
            letter-spacing: -0.078rem;
            line-height: 1.25;
          }
        }
      }
    }
  }
}
