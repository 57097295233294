.size-select-container {
    height: 75px;
    width: 45px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    // font-weight: 600;
    // opacity: 0.75;
    .size-part {
        font-size: 25px;
        align-self: flex-start;
        font-family: "proxima-nova", sans-serif !important;
        font-weight: 100 !important;
    }
    .small-text {
        font-size: 15px;
        font-weight: 545;
        color: grey;
    }
}

.small-size {
    height: fit-content;
    width: fit-content;
    margin: 0px;
    padding: 0px;
    position: relative;
    margin-left: 30px;
    i {
        position: absolute;
        top: 30%;
        font-size: 12px;
    }
}

.size-chart-box {
    left: 450px;
    z-index: 10;
    position: absolute;
    width: 17vw;
    padding-top: 5px;
    height: 325px;
    background-color: white;
    opacity: 1;
    border-radius: 5px;
}