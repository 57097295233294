.size-selector-mob {
  position: absolute;
  width: 100%;
  height: 360px;

  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-width: 1.75px;
  padding: 5px;
  border-radius: 5px;
  z-index: 999;
  background-color: white;

  .sizeboxes-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    overflow-y: auto;
    padding-bottom: 20px;

    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .scroll-indicator {
    position: absolute;
    inset: auto 0 0 0;
    height: 10px;
    background: red;
    pointer-events: none;

    &:after {
      content: "";
      position: absolute;
      left: 0px;
      bottom: 0px;
      height: 40px;
      width: 100%;
      pointer-events: none;
      background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 30%,
        white 70%
      );
      z-index: 1;
    }
  }
  // @media screen and (min-width: 481px) {
  //   width: 330px;
  // }
  @media screen and (max-width: 481px) {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}
