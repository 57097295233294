@media screen and (max-width: 480px) {
  .suggestions .CustomcardDiv {
    width: 140px !important;
    height: 230px !important;
    .cardImg {
      height: 90px !important;
      margin: 0px 0px 0px 12px !important;
    }
    .textCont {
      .headingDiv {
        font-size: 0.8rem !important;
        h6 {
          font-size: 0.8rem !important;
        }
      }
      .priceDiv {
        span {
          font-size: 0.6rem !important;
        }
        h6 {
          font-size: 1rem !important;
        }
      }
      .lastSoldDiv {
        span {
          padding: 5px !important;
          font-size: 0.7rem !important;
        }
      }
    }
  }
}

.suggestions .CustomcardDiv {
  margin: 0px !important;
  padding: 0px !important;
  width: 220px;

  height: 275px;
  margin: 0px !important;
  background-color: #fff;
  border: 0.631049px solid #d9d9d9;
  border-radius: 0px !important;
  cursor: pointer;
  .cardImg {
    height: 130px;
    width: 85%;
    margin: 0px 0px 0px 16px;
    object-fit: contain;
  }
  .textCont {
    width: 90%;
    height: auto !important;
    margin: 10px auto;
    color: black !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    .headingDiv {
      width: 100%;
      height: auto !important;
      overflow: hidden;
      font-family: "SuisseIntlMedium" !important;
      font-size: 1rem;
      h6 {
        font-size: 1rem;
      }
    }
    .priceDiv {
      width: 100%;
      span {
        font-size: 0.75rem;
      }
      h6 {
        font-size: 1.25rem;
      }
    }
    .lastSoldDiv {
      width: 100%;
      span {
        width: auto;
        padding: 7px;
        font-size: 0.75rem;
        background-color: hsl(0, 0%, 94%);
      }
    }
  }
}

// .suggestions .CustomcardDiv {
//   height: 270px;
//   width: 140px;
//   background-color: #fff;
//   border: 0.631049px solid #d9d9d9;
//   border-radius: 6.31049px;
//   margin: 0px auto;
//   cursor: pointer;
//   .cardImg {
//     height: 120px;
//     width: 100%;
//     object-fit: contain;
//   }
//   .textCont {
//     width: 90%;
//     margin: 10px auto;
//     color: black !important;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     flex-direction: column;
//     .headingDiv {
//       width: 100%;
//       overflow: hidden;
//       h6 {
//         font-size: 0.875rem;
//       }
//     }
//     .priceDiv {
//       width: 100%;
//       span {
//         font-size: 0.75rem;
//       }
//       h6 {
//         font-size: 1.25rem;
//       }
//     }
//     .lastSoldDiv {
//       width: 100%;
//       span {
//         width: auto;
//         padding: 7px;
//         font-size: 0.75rem;
//         background-color: hsl(0, 0%, 94%);
//       }
//     }
//   }
// }

.suggestions {
  h1 {
    font-size: 1.125rem;
  }
  .sug-txt {
    color: black;
    // font-family: proxima-nova, sans-serif;
    font-family: proxima-nova, sans-serif !important;
    font-size: 30px;
    margin-top: 20px;
    text-align: left;
    padding-left: 60px;
    // font-family: "Montserrat";
    // font-weight: 350;
    font-family: "proxima-nova", sans-serif !important;
    font-weight: 100 !important;
    .sug-txt {
      color: black;
      // font-family: proxima-nova, sans-serif;
      font-family: proxima-nova, sans-serif !important;
      font-size: 30px;
      margin-top: 20px;
      text-align: left;
      padding-left: 60px;
      font-family: "Montserrat";
      font-weight: 350;
    }
    img {
      max-width: 250px;
      max-height: 300px;
    }
    .row {
      margin: 10px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
    }
    .cardDeck {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    .cardownItem {
      height: auto;
      width: auto;
      border: 0px !important;
      align-items: center;
      margin: 0px;
    }
    .cTxt {
      margin: 0px;
      font-size: 1rem;
      text-align: center;
    }
    @media screen and (max-width: 820px) {
      .txt {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      img {
        max-width: 300px;
        max-height: 300px;
      }
    }
  }
}
