.shoe-sell {
  margin-right: 0px;
  padding: 0px 60px;
  height: auto;
  width: 95%;
  margin-left: 2.5%;
  @media screen and (max-width: 1250px) {
    padding: 0px 0px;
  }
  @media screen and (max-width: 1000px) {
    margin-right: 0px;
  }
  @media screen and (max-width: 800px) {
    margin-right: 15px;
  }
  @media screen and (max-width: 450px) {
    margin-right: 0px;
  }
  .sell-header {
    background-color: white;
    border-radius: 0px;
    border: 1px solid #e5e7ea;
    display: flex;
    margin-bottom: 2px;
    height: 50px;
    justify-content: space-between;
    .size-info {
      padding: 13px;

      p {
        margin: 0px;
        font-weight: 575;
      }
    }
    i {
      width: 1px;
      height: 1px;
      margin-top: 10px;
      margin-right: 30px;
      color: grey;
      cursor: pointer;
    }
  }
  .sell-body {
    margin-top: 20px;
    background-color: white;
    border: 1px solid #e5e7ea;
    border-radius: 2px;
    padding: 20px 20px 15px 20px;
    .button-group {
      background-color: rgba(226, 226, 226, 255);
      display: flex;
      border-radius: 18px;
      button {
        outline: none;
        border: none;
        width: 100%;
        height: 50px;
        border-radius: 18px;
        font-size: 14px;
        font-weight: 575;
        transition: all 0.3s ease;
      }
      .inactive {
        background-color: rgba(226, 226, 226, 255);
        color: black;
        &:hover {
          color: rgba(237, 28, 36, 255);
        }
      }
      .active {
        background-color: #ff0000;
        color: white;
      }
    }
  }
}
