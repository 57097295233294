.collage-item {
  position: relative;

  &:hover {
    box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.15) !important;

    .collage-item-info {
      opacity: 1;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .collage-item-info {
    opacity: 0;
    transition: 300ms;
    display: flex;
    z-index: 2;
    align-items: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    padding: 5px;

    .info-block {
      background: #fff;
      padding: 5px 10px 8px;

      .heading {
        font-size: 1.125rem;
        letter-spacing: -0.042rem;
        line-height: 1.333;
        font-family: GesturaReg !important;

        @media only screen and (min-width: 992px) {
          font-size: 1.375rem;
          letter-spacing: -0.045rem;
          line-height: 1.364;
        }
      }

      .meta {
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 0.05375rem;
        line-height: 1.667;
        margin: 0;
      }
    }
  }
}
