@import "../../variables.scss";

.navbar {
  height: $navbar-height;

  .navbar-toggle-btn {
    width: 40px;
    height: 50px;
    border: 0px !important;
    outline: 0px !important;
    background-color: transparent;
    display: none;

    img {
      width: 25px;
    }
  }

  .navbar-brand {
    width: 200px;

    img {
      width: 100%;
    }
  }

  .navbar-nav {
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .auth-buttons {
      display: flex;
      gap: 1rem;
    }
  }

  .sbar {
    position: absolute;
    z-index: 1000;
    display: none;

    .clear {
      inset: 0;
    }
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $mobile-navbar-height;
    padding: 0px 1rem !important;

    .navbar-toggle-btn {
      display: block;
    }

    .sbar {
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    .navbar-brand {
      width: 130px;
    }
  }
}

/* @media screen and (max-width: 480px) {
  .navbrand {
    width: 130px !important;
  }

  .container-fluid {
    display: flex !important;
    flex-direction: row-reverse !important;
  }

  .headerSection {
    height: 10vh;
    background-color: white !important;
  }

  .searchBtnHeader img {
    width: 20px !important;
  }
  .smallMenuBtns img {
    width: 25px;
  }
}

.headerSection {
  height: 10vh;
}

.headerBtn {
  padding: 8px 24px 8px 24px !important;
  font-size: 17px;

  margin-right: 10px !important;
}

.nav-priconmob {
  display: none;
}
.container-fluid .smallMenuBtns {
  width: 40px;
  height: 50px;
  border: 0px !important;
  outline: 0px !important;
  background-color: transparent;
  display: none;
}
.container-fluid .smallMenuBtns .MuiSvgIcon-root {
  font-size: 35px;
  color: grey;
  display: none;
}

.navbar {
  border-bottom: 1px solid #e2e8f0 !important;
}
.navbar-brand {
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

#basic-nav-dropdown::after {
  display: none;
}
.loginButtonCustom {
  padding: 10px 20px;
  background-color: white;
  border: 1px solid red;
  color: red !important;
  transition: all 0.3s ease-in-out;
}
.loginButtonCustom:hover {
  background-color: red;
  color: white !important;
}
.centeredBtnsClass {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px 0px 10px !important;
}

.sldbtn {
  margin-right: 45px;
  padding-top: 5px;
}

.navs {
  font-size: 16px;
  font-weight: 649;
}

@media screen and (max-width: 830px) {
  .nav-pricon {
    display: flex;
  }

  .nav-priconmob {
    display: block;
  }

  .navbar-brand {
    margin: 0px;
  }

  .sldbtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
  }

  .sellbtn {
    display: none;
  }
}

.searchIcon:hover {
  background: url("http://dummyimage.com/100x100/eb00eb/fff");
} */
