@import "../../../variables.scss";

.blog-collage {
  .custom-wrapper {
    max-width: 100vw;

    .header {
      max-width: 1295px;
      margin: 123px auto 30px;
      width: 94%;
      text-align: center;

      h5 {
        font-size: 2.375rem;
        letter-spacing: -0.026rem;
        line-height: 1.125;
        font-family: SuisseIntlMedium !important;
      }

      p {
        opacity: 0.6;
        font-size: 1.5rem;
        letter-spacing: -0.035rem;
      }
    }
  }

  .splide__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;

    .collage-item-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-block: 100px 80px;
      gap: 1rem;

      &.container-1 {
        margin-block: 20px 50px;
      }

      &.container-2 {
        margin-block: 150px 80px;
      }
      &.container-3 {
        margin-block: 80px 10px;
      }

      .collage-item {
        flex: 1;
        width: 250px;
        aspect-ratio: 1;
        box-shadow: 0 6px 17px 0 transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 2px;
        overflow: hidden;
        background-color: #acacac;

        @media only screen and (min-width: 992px) {
          width: 300px;
          scroll-snap-align: unset;
        }
      }
    }
  }

  .control {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10%;
    z-index: 1000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      transition: all 0.2s ease-in-out;
    }

    &.control-prev {
      left: 0;

      &:hover {
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
    &.control-next {
      right: 0;

      &:hover {
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }

  @media only screen and (min-width: 992px) {
    .header {
      margin: 0 auto 30px;
    }
  }
}
