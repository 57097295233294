@import "../../../variables.scss";

.blogs-pagination {
  margin: 30px 0 80px;
  justify-content: center;
  display: flex;

  .pagination {
    display: flex;
    max-width: 100%;

    .pagination-container {
      background: #f6f6f6;
      border-radius: 5px;
      padding: 6px;
      display: flex;
    }
    .pagination__btn {
      background: 0 0;
      border-radius: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: black;
      transition: all 0.2s ease-in-out;

      &.active {
        background: $primary;
        color: #fff;
      }

      &:not(.pagination__btn--ellipsis):hover {
        background: $primary;
        color: #fff;
        transition: all 0.2s ease-in-out;
      }

      &.pagination__btn--previous,
      &.pagination__btn--next {
        color: $primary;
        border: 1px solid $primary;
        width: 42px;
        height: 42px;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: #fff;
          background: $primary;
          transition: all 0.2s ease-in-out;
        }

        &:disabled {
          background: orange;
        }
      }

      &.pagination__btn--next {
        margin: 0 0 0 10px;
      }
      &.pagination__btn--previous {
        margin: 0 10px 0 0;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .pagination {
      .pagination__container {
        padding: 9px 13px;
      }
      .pagination__btn {
        width: 42px;
        height: 42px;

        &.pagination__btn--previous,
        &.pagination__btn--next {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}
