@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

.page-container {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .two-factor-container {
    width: 20%;
    // height: 85%;
    height: 113%;
    min-width: 370px;
    min-height: 350px;
    border: 1px solid gainsboro;
    border-radius: 4px;
    padding: 10px 20px;
    // margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 481px) {
      min-width: 330px;
      padding: 10px 5px;
    }
    .shield {
      font-size: 56px;
      margin-bottom: 20px;
      color: rgba(217, 28, 36, 255);
      @media screen and (max-width: 450px) {
        font-size: 42px;
      }
    }
    .two-factor-text {
      text-align: center;
      h3 {
        font-weight: 475;
        font-size: 24px;
        margin-bottom: 20px;
        @media screen and (max-width: 450px) {
          font-size: 20px;
        }
      }
      p {
        color: rgba(131, 131, 131, 255);
        font-size: 15px;
        font-weight: 475;
        width: 300px;
        margin-bottom: 20px;
        @media screen and (max-width: 450px) {
          font-size: 12px;
        }
      }
    }
    .form-container {
      width: 70%;
      height: 100;
      position: relative;
      label {
        font-family: "Raleway", sans-serif;
        font-size: 15px;
        font-weight: 470;
        color: grey;
        position: absolute;
        z-index: 1;
        top: 20px;
        left: 12px;
        transition: 150ms ease all;
        cursor: text;
        @media screen and (max-width: 450px) {
          font-size: 13px;
          top: 24px;
        }
      }
      .float-label {
        font-size: 10px;
        top: 12px;
      }
      input {
        margin: 7px 0px;
        width: 100%;
        border: 1px solid gainsboro;
        padding: 15px 0px 12px 10px;
        border-radius: 3px;
        outline: none;
        &:focus {
          border: 1px solid rgba(237, 28, 36, 255);
        }
      }
      &:last-of-type {
        margin-bottom: 30px;
      }
    }
    .form-container1 {
      width: 25%;
      height: 100;
      position: relative;
      label {
        font-family: "Raleway", sans-serif;
        font-size: 15px;
        font-weight: 470;
        color: grey;
        position: absolute;
        z-index: 1;
        top: 20px;
        left: 12px;
        transition: 150ms ease all;
        cursor: text;
        @media screen and (max-width: 450px) {
          font-size: 13px;
          top: 24px;
        }
      }
      .float-label {
        font-size: 10px;
        top: 12px;
      }
      input {
        margin: 7px 0px;
        width: 100%;
        border: 1px solid gainsboro;
        padding: 15px 0px 12px 10px;
        border-radius: 3px;
        outline: none;
        &:focus {
          border: 1px solid rgba(237, 28, 36, 255);
        }
      }
      &:last-of-type {
        margin-bottom: 30px;
      }
    }
  }
}

/**
 * Main wrapper
 */

.select-search {
  width: 300px;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
 * Value wrapper
 */

.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
 * Input
 */

.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
 * Options wrapper
 */

.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */

.select-search__options {
  list-style: none;
}

/**
 * Option row
 */

.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
 * Option
 */

.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2fcc8b;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
 * Group
 */

.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
 * States
 */

.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
 * Modifiers
 */

.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.css-26l3qy-menu {
  z-index: 1000 !important;
}
