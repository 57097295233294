.buyers-blog-slide {
  width: 100%;
  height: 100%;
  min-height: 250px;
  min-width: 250px;
  max-height: 360px;
  max-width: 360px;
  aspect-ratio: 1 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  .image-wrapper {
    flex: 5;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  p {
    flex: 1;
    font-size: 1.125rem;
    letter-spacing: -0.0469rem;
    line-height: 1.444;
  }
}
