.selling-info-section {
  max-width: 60vw;
  min-width: 60vw;
  margin-top: 70px;
  @media screen and (max-width: 481px) {
    margin-top: 10px;
    max-width: 70vw;
    min-width: 70vw;
  }
  .selling-info-content {
    p {
      font-size: 15px;
      font-weight: 575;
      color: black;
    }
  }
}
