.shpbnr {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers the image horizontally */

  .bnrImg {
    align-self: center;
    align-items: center;
    max-width: fit-content;
    height: fit-content;
  }
  .bnrImg_img {
    width: 100%;
    height: 50vh;
    object-fit: contain; /* Maintains aspect ratio and fits within the container */
    align-self: center;
  }
  .bnrtxt {
    text-align: center;
    font-size: 30px;
    font-family: "proxima-nova", sans-serif !important;
    font-weight: 100 !important;
  }
  .prdTxt {
    text-align: center;
    width: 100%;
    align-self: center;
    margin-top: 20px;
    // font-family: "Montserrat";
    // font-weight: 300;
    font-family: "proxima-nova", sans-serif !important;
    font-weight: 100 !important;
  }
}

.shpbnrMob {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .bnrImg {
    align-self: center;
    align-items: center;
    max-width: fit-content;
    height: fit-content;
  }
  img {
    width: 100%;
    align-self: center;
    object-fit: contain;
  }
}

// Mobile View
@media only screen and (max-device-width: 768px) {
  .shpbnr {
    display: none;
  }
}

// Web View
@media (min-width: 769px) {
  .shpbnrMob {
    display: none;
    // background: red;
  }
}

// @media screen and (max-width: 1920px) {
//   .shpbnr {
//     height: 30vh;
//     // background: red;
//   }
//   .shpbnrMob {
//     display: none;
//     // background: red;
//   }
// }
// @media only screen and (max-width: 600px) {
//   .shpbnrMob {
//     height: 35vh;
//     display: block;
//     // background: red;
//   }
//   .shpbnr {
//     display: none;
//   }
// }
