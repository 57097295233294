.setting-item-container {
  height: auto;
  margin: 3px;
  margin-top: 0px;
  display: flex;
  @media screen and (max-width: 481px) {
    width: 100%;
    height: fit-content;
    justify-content: center;
  }
  .item-container {
    margin-left: 10px;
    position: relative;
    width: 40%;
    @media screen and (max-width: 1000px) {
      width: 60%;
    }
    @media screen and (max-width: 481px) {
      margin-left: 0px;
      width: 125%;
      height: fit-content;
    }
  }
  .heading-container {
    //font-family: "Raleway", sans-serif;
    margin-top: 12px;
    font-weight: 500;
    width: 20%;
    padding-left: 10px;
    border-bottom: 2px solid gainsboro;
    height: auto;
    position: relative;
    @media screen and (max-width: 1000px) {
      width: 40%;
      i {
        margin-top: 5px;
      }
    }
    @media screen and (max-width: 500px) {
      font-size: 14px;
      width: 25%;
    }
    i {
      position: absolute;
      left: 100%;
      cursor: pointer;

      &:hover {
        color: rgba(237, 28, 36, 255);
      }
    }
  }

  .heading-containerx {
    //font-family: "Raleway", sans-serif;
    margin-top: 12px;
    font-weight: 500;
    width: 20%;
    border-bottom: 2px solid gainsboro;
    height: auto;
    position: relative;
    @media screen and (max-width: 1000px) {
      width: 40%;
      i {
        margin-top: 5px;
      }
    }
    @media screen and (max-width: 600px) {
      font-size: 14px;
      width: 25%;
    }
    i {
      position: absolute;
      left: 100%;
      cursor: pointer;

      &:hover {
        color: rgba(237, 28, 36, 255);
      }
    }
  }

  .heading-containery {
    //font-family: "Raleway", sans-serif;
    // display: flex !important;
    // flex-direction: column !important;
    font-weight: 500;
    width: 20%;
    border-bottom: 2px solid gainsboro;
    height: 100%;
    position: relative;
    @media screen and (max-width: 1000px) {
      width: 40%;
      i {
        margin-top: 5px;
      }
    }
    @media screen and (max-width: 481px) {
      font-size: 14px;
      height: auto;
      width: 25%;
    }
    i {
      position: absolute;
      left: 100%;
      cursor: pointer;

      &:hover {
        color: rgba(237, 28, 36, 255);
      }
    }
  }

  .tier-two {
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
}
