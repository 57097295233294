.product-image-slider {
  .main-swiper {
    --swiper-navigation-color: #dc3545;
  }

  .thumbs-swiper {
    .swiper-slide {
      width: 25%;
      height: 100%;
      opacity: 0.4;
    }
    .swiper-slide-thumb-active {
      opacity: 1;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
