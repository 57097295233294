.px-container {
  width: fit-content;
  height: auto;
  border-style: solid;
  border-width: 2px;
  border-color: whitesmoke;
  padding: 0;
  display: flex;
  flex-direction: column;

  .card-btn-container {
    width: 18rem;
  }
  .dtTxt {
    font-size: small;
  }
  .btnWnt {
    font-size: small;
    border-width: 2px;
    background-color: #ec1d25 !important;
    border-color: #ec1d25 !important;
    color: white !important;
    padding: 0 !important;
    height: 25px;
    width: 60px;
    text-align: center;
  }
  .btnWnt:active {
    background-color: #ec1d25 !important;
    border-color: #ec1d25 !important;
    color: white !important;
  }
  .card {
    height: auto;
    width: 18rem;
    border: none;
    align-items: center;
    margin: 0px;
    .card-title {
      font-size: small;
    }
    .card-text {
      font-size: small;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 600;
      .oldTxt {
        text-decoration-line: line-through;
      }
      .newTxt {
        color: red;
      }
    }
    img {
      height: 200px;
      width: 200px;
      object-fit: contain;
    }
  }
  .card:hover {
    cursor: pointer;
  }
}

.px-container:hover {
  border-color: darkgrey;
}

.large {
  padding-top: 20px;
  padding-left: 27px;
  padding-right: 27px;
  padding-bottom: 30px;
}

@media screen and (min-width: 600px) and (max-width: 710px) {
  .large {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
}

@media screen and (min-width: 495px) and (max-width: 600px) {
  .large {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .px-container {
    width: 15rem;
    height: auto;
    .card-btn-container {
      width: 15rem;
    }
    .card {
      height: auto;
      width: 13rem;
      border: none;
      align-items: center;
      margin-left: 15px;
      img {
        height: 200px;
        width: 200px;
      }
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 495px) {
  .large {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .px-container {
    width: 50%;
    height: auto;
    .card-btn-container {
      width: 9.5rem;
    }
    .card {
      height: auto;
      width: 9.5rem;
      border: none;
      align-items: center;
      margin-left: 12px;
      img {
        height: 100px;
        width: 100px;
      }
    }
  }
}

// .shoe-card {
//   cursor: pointer;
//   width: 20%;
//   position: relative;

//   .shoe-content {
//     display: flex;
//     flex-direction: column;
//     justify-content: end;
//     min-width: 141px;
//     height: 100%;
//     position: relative;
//     margin-right: 0px;
//     border: 2px solid whitesmoke;

//     .date {
//       position: absolute;
//       top: 5%;
//       left: 10%;
//       font-size: small;
//       z-index: 10;
//     }

//     .new-tag {
//       position: absolute;
//       top: 5%;
//       right: 10%;
//       background-color: #ec1d25 !important;
//       padding: 3px 10px;
//       font-size: 13px;
//       z-index: 10;
//       color: white;
//       border-radius: 5px;
//     }

//     .item-details {
//       padding: 1.25rem;
//       display: flex;
//       flex-direction: column;
//       flex: 1;

//       .item-image {
//         flex: 1;

//         img {
//           width: 100%;
//           height: 100%;
//           object-fit: contain;
//         }
//       }
//       .item-title {
//         text-align: center;
//         margin-top: 1.25rem;
//         font-size: 14px;
//         letter-spacing: 0.004rem;
//       }
//       .item-price {
//         text-align: center;
//         color: red;
//         font-weight: bold;
//         font-size: 14px;
//         letter-spacing: 0.004rem;
//       }
//     }
//   }
// }

// @media (max-width: 991px) {
//   .shoe-card {
//     width: 33%;
//   }
// }
// @media (max-width: 768px) {
//   .shoe-card {
//     width: 50%;
//   }
// }
