@import "../../variables.scss";

.blog-category-page {
  padding-top: 125px;
  display: flex;
  flex-direction: column;

  .container {
    max-width: 1295px;
    margin: 0 auto;
    width: 94%;
    position: relative;
  }

  .container--v2 {
    max-width: 770px;
  }

  .headline {
    font-family: GesturaReg !important;
    font-size: 6.25rem;
    letter-spacing: -0.048rem;
  }

  .headline--1 {
    font-family: GesturaReg !important;
    font-size: 2.375rem;
    letter-spacing: -0.026rem;
    line-height: 1.105;
    font-family: GesturaReg !important;
  }

  .headline--2 {
    font-size: 2.375rem;
    letter-spacing: -0.026rem;
    line-height: 1.125;
    font-family: GesturaReg !important;
  }

  .headline--3 {
    font-size: 1.375rem;
    letter-spacing: -0.033rem;
    line-height: 1.18;
    font-family: GesturaReg !important;
  }

  .headline--4 {
    font-size: 1.125rem;
    letter-spacing: -0.042rem;
    line-height: 1.333;
    font-family: GesturaReg !important;
  }

  .headline--6 {
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.07rem;
  }

  .main-banner {
    position: relative;
    width: 100%;
    padding: 80px 0 85px;
    background: rgba($primary, 0.75);

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0;
      object-fit: cover;
      object-position: center center;
      z-index: 1;
    }

    .container {
      z-index: 100;

      h1 {
        color: white;
      }

      p.description {
        max-width: 877px;
        margin: 20px 0 0;
        font-size: 1.125rem;
        line-height: 1.333;
        font-weight: bold;

        &.text-shadow {
          text-shadow: 0px 0px 10px white;
        }
      }
    }
  }

  section {
    .container {
      .blog-list {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding: 60px 0 0;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    section {
      .container {
        .blog-list {
        }
      }
    } // End of section
  }

  @media only screen and (min-width: 992px) {
    display: block;

    .headline--1 {
      margin: 12px 0 0;
      font-size: 2.375rem;
    }

    .headline--2 {
      font-size: 3rem;
      letter-spacing: -0.031rem;
    }

    .headline--3 {
      font-size: 1.75rem;
      letter-spacing: -0.053rem;
      line-height: 1.364;
    }

    .main-banner {
      padding: 130px 0 150px;

      .container {
        p.description {
          font-size: 1.375rem;
          line-height: 1.364;
        }
      }
    } // End of main-banner

    section {
      .container {
        .blog-list {
          width: calc(100% + 15px);
          margin: 0 0 0 -7.5px;
        }
      }
    } // End of section
  }

  @media only screen and (min-width: 1200px) {
    .headline--1 {
      font-size: 3.75rem;
    }
  }
}
