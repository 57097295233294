@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
.page-container {
  margin-top: 150px;
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .conformPassword-container {
    width: 20%;
    height: 85%;
    min-width: 370px;
    min-height: 350px;
    border: 1px solid gainsboro;
    border-radius: 4px;
    padding: 10px 20px;
    // margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 450px) {
      min-width: 310px;
    }
    .shield {
      font-size: 56px;
      margin-bottom: 20px;
      color: rgba(217, 28, 36, 255);
      @media screen and (max-width: 450px) {
        font-size: 42px;
      }
    }
    .conformPassword-text {
      text-align: center;
      h3 {
        font-weight: 475;
        font-size: 24px;
        margin-bottom: 20px;
        @media screen and (max-width: 450px) {
          font-size: 20px;
        }
      }
      p {
        color: rgba(131, 131, 131, 255);
        font-size: 15px;
        font-weight: 475;
        width: 300px;
        margin-bottom: 20px;
        @media screen and (max-width: 450px) {
          font-size: 12px;
        }
      }
    }
    .form-container-conformPassword {
      width: 100%;
      height: 100;
      position: relative;

      label {
        font-family: "Raleway", sans-serif;

        font-size: 15px;
        font-weight: 470;
        color: grey;
        position: absolute;
        z-index: 1;
        top: 20px;
        left: 12px;
        transition: 150ms ease all;
        cursor: text;
        @media screen and (max-width: 450px) {
          font-size: 13px;
          top: 24px;
        }
      }
      .float-label-conformPassword {
        font-size: 10px;
        top: 12px;
      }
      input {
        margin: 7px 0px;
        width: 100%;
        border: 1px solid gainsboro;
        padding: 15px 0px 12px 10px;
        border-radius: 3px;
        outline: none;
        &:focus {
          border: 1px solid rgba(237, 28, 36, 255);
        }
      }
      &:last-of-type {
        margin-bottom: 30px;
      }
    }
  }
}
