.szBtn {
  font-size: 13px;
  font-weight: 600;
  background-color: white;
  border-width: 1px;
  border-color: black;
  color: black;
  padding-top: 10px;
  height: 60px;
  width: 80px;
  margin: 2px;
  align-items: center;
}

.szBtn:hover {
  background-color: white;
  border-color: gray;
  color: black;
}

.szBtn:active {
  background-color: white !important;
  border-color: gray !important;
  color: black !important;
}

.szBtnPro {
  font-size: 13px;
  font-weight: 600;
  background-color: white;
  border-width: 1px;
  border-color: black;
  color: black;
  padding-top: 10px;
  height: 40px;
  width: 37px;
  margin: 2px;
  align-items: center;
}

.szBtnPro:hover {
  background-color: white;
  border-color: gray;
  color: black;
}

.szBtnPro:active {
  background-color: white !important;
  border-color: gray !important;
  color: black !important;
}

.btn {
  padding: 0.375rem 0.5rem !important;
}
