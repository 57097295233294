.author-section {
  max-width: 770px;
  margin: 0 auto;
  width: 94%;
  position: relative;

  .content {
    display: flex;
    padding: 26px 0 60px;
    border-top: 1px solid #d8d8d8;

    .author-image {
      margin: 0 19px 0 0;
      background: #ccc;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      overflow: hidden;

      .author-image-placeholder {
        width: 100%;
        height: 0;
        padding-top: 100%;
        position: relative;

        img {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center center;
        }

        svg {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 60%;
          left: 50%;
          transform: translate(-50%, -50%);

          path {
            fill: #0006;
          }
        }
      }
    }

    .author-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .author-content-name {
        font-size: 1.375rem;
        letter-spacing: -0.033rem;
        line-height: 1.18;
        font-family: gestura reg;
      }
      .author-content-meta {
        margin: 5px 0 0;
        opacity: 0.6;
        font-size: 1.125rem;
        line-height: 1.111;
      }
    }
  }
}
