.size-box-container {
  // width: 90px;
  cursor: pointer;
  background-color: white;
  width: 90px;
  height: 55px;
  border-style: solid;
  border-color: lightgrey;
  border-width: 1.5px;
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  transition: all 0.25s;

  &:hover {
    transition: all 0.25s;
    background-color: lightgrey;
  }
}
