@import "@splidejs/react-splide/css";

@import "../../../variables.scss";

.splide.related-blogs {
  margin-top: 4rem;

  .custom-wrapper {
    max-width: 100vw;

    .header {
      margin: 123px auto 30px;
      width: 100%;
      text-align: center;

      h1 {
        font-size: 4rem;
        font-family: SuisseIntlMedium !important;
      }

      @media only screen and (min-width: 992px) {
        margin: 0 auto 30px;
      }
    }

    .splide__slide {
      min-width: 250px;
      aspect-ratio: 1;
    }

    .control {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10%;
      z-index: 1000;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        transition: all 0.2s ease-in-out;
      }

      &.control-prev {
        left: 0;

        &:hover {
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(0, 0, 0, 0) 100%
          );
        }
      }
      &.control-next {
        right: 0;

        &:hover {
          background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(255, 255, 255, 1) 100%
          );
        }
      }
    }
  }
}
