* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-family: "proxima-nova", sans-serif !important;
}

.App {
  background-color: #efefef;
  // height: 30vw;
  padding: 4vw 10vw;
}

.carousel-card {
  padding: 10px 15px 25px 15px;
  background-color: #fff;
  // height: 250px;
  text-align: center;
  border-radius: 3px;
  margin-top: 10vw;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
  height: 280px;
}

.icon-div {
  height: 80px;
}

.post-name {
  margin: 20px 0;
  font-weight: 400 !important;
  font-size: 1rem;
  line-height: 1.555;
  color: rgb(26, 32, 44);

  font-family: "Poppins", sans-serif;
}

.post-by {
  margin-top: 20px;
  font-size: 1.2rem !important;
  // font-weight: 800;
  font-family: "Poppins", sans-serif;
}

.button.slick-arrow.slick-next {
  background-color: green !important;
}

.slick-prev,
.slick-next {
  /* padding: 20px !important; */
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
}

.slick-prev:before {
  right: 5px !important;
}

.slick-prev:before,
.slick-next:before {
  color: #000 !important;
  opacity: 1 !important;
  line-height: 0 !important;
  position: relative;
  top: 5px;
  font-size: 40px !important;
}

button.slick-arrow.slick-prev {
  z-index: 10;
}

.review-title {
  text-align: center;
  font-weight: 600;
  font-size: 4rem;
  letter-spacing: -0.031rem;
  margin-bottom: 4vw;
  font-family: "Poppins", sans-serif;

  // font-family: "proxima-nova", sans-serif !important;
}

@media only screen and (max-width: 768px) {
  .App {
    padding: 7vw;
  }
  .carousel-card {
    margin-top: 30vw;
    height: 300px;
  }
  .review-title {
    font-size: 2rem;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 35px !important;
  }
}
