.returnable-cell-container {
  background-color: rgba(226, 226, 226, 255);
  display: flex;
  border-radius: 18px;
  margin-bottom: 5px;
  margin-top: 5px;

  button {
    outline: none;
    border: none;
    width: 100%;
    height: 30px;
    border-radius: 18px;
    font-size: 14px;
    font-weight: 575;
    transition: all 0.3s ease;
  }
  .inactive {
    background-color: rgba(226, 226, 226, 255);
    color: black;
    &:hover {
      color: rgba(237, 28, 36, 255);
    }
  }
  .active {
    background-color: #ff0000;
    color: white;
  }
}

.returnable-label {
    font-size: 14px;
    color: rgba(126, 126, 126, 255);
    font-weight: 575;
    i{
      font-size: 13.5px;
      position: relative;
      margin-right: 2px;
  
    }
}
