@import "../../variables.scss";

.app {
  &:has(header.blogs-header.offcanvas) {
    max-height: 100vh !important;
    overflow-y: hidden;
  }
}

header.blogs-header {
  padding: 18px 0;
  background: #fff;
  z-index: 10000;
  transition: 300ms;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;

  &.shrink {
    padding: 10px 0;
  }

  &.offcanvas {
    .header__blog {
      color: white !important;
    }
  }

  &.dark {
    background-color: black;
  }

  @media only screen and (min-width: 992px) {
    padding: 25px 0;

    .header__container {
      margin: 0 auto !important;
      width: 94% !important;

      .header__content {
        .header__blog {
          display: block !important;
        }

        .header__brand {
          width: 110px;
        }

        .header__actions {
          .header__action-btn {
            width: 60px !important;
            height: 60px !important;
          }
        }
      }
    }
  }

  .header__container {
    position: relative;
    z-index: 101;
    max-width: 1295px;
    margin: 0 auto;
    width: 94%;

    .header__content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header__brand {
        width: 120px;
        position: relative;

        .header__brand-placeholder {
          width: 100%;
          padding-top: 46.4%;
          height: 0;
          position: relative;

          img.navbrand {
            object-fit: contain;
            width: 100% !important;
            height: 100% !important;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      .header__blog {
        display: none;
        text-align: center;
        transition: 300ms;
        color: black;

        .header__blog-title {
          display: inline-block;
          font-size: 1.5rem;
          border-bottom: 2px solid $primary;
          font-family: SuisseIntlMedium !important;
          margin-bottom: 5px;
        }

        .header__blog-description {
          opacity: 0.5;
          margin: 5px 0 0;
        }
      }

      .header__actions {
        display: flex;
        z-index: 2;

        .header__action-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #000;
          position: relative;
          transition: 300ms;
          width: auto;
          background: #000;
          color: #fff;
          border-radius: 3px;
          width: 40px;
          height: 40px;

          &.btn-nav {
            font-size: 1.25rem;
            margin: 0 0 0 10px;

            &:hover {
              background: white;
              color: black;
            }
          }
        }
      }
    }
  }
}
