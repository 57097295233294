.account-sidebar-container {
  background-color: rgba(241, 241, 241, 255);
  position: fixed;
  height: 100vh;
  width: 250px;
  z-index: 100;
  min-width: 240px;
  padding-top: 115px;
  font-family: "Raleway", sans-serif;
  @media screen and (max-width: 1100px) {
    left: -100%;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.25px;
    margin-left: 10px;
    margin-bottom: 15px;
  }
  .link-container {
    display: flex;
    align-items: center;
    padding: 20px 10px;

    &:hover {
      background-color: rgba(225, 225, 225, 255);
    }

    img {
      width: 38px;
      height: 38px;
      margin-right: 12px;
    }
    .link-text {
      line-height: 0;
      .link-name {
        font-size: 14px;
        margin: 0px;
        line-height: 1.5rem;
      }
      span {
        color: rgba(131, 131, 131, 255);
        margin: 0px;
        font-size: 12.5px;
        font-weight: 575;
        line-height: 1rem;
      }
    }
  }
  .selected {
    background-color: rgba(251, 251, 251, 255);
  }
}
.small-account-sidebar-container {
  background-color: rgba(241, 241, 241, 255);
  position: fixed;
  z-index: 100;
  height: 100vh;
  width: 250px;
  min-width: 240px;
  padding: 35px 0px;
  font-family: "Raleway", sans-serif;
  @media screen and (max-width: 1100px) {
    width: 100%;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.25px;
    margin-left: 10px;
    margin-bottom: 15px;
  }
  .link-container {
    display: flex;
    align-items: center;
    padding: 20px 10px;

    &:hover {
      background-color: rgba(225, 225, 225, 255);
    }

    img {
      width: 38px;
      height: 38px;
      margin-right: 12px;
    }
    .link-text {
      line-height: 0;
      .link-name {
        font-size: 14px;
        margin: 0px;
        line-height: 1.5rem;
      }
      span {
        color: rgba(131, 131, 131, 255);
        margin: 0px;
        font-size: 12.5px;
        font-weight: 575;
        line-height: 1rem;
      }
    }
  }
  .selected {
    background-color: rgba(251, 251, 251, 255);
  }
}
