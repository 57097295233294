.faq-container {
  margin-top: 170px;
  margin-bottom: 100px;
  padding-left: 20vw;
  padding-right: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Raleway", sans-serif;

  h1 {
    font-weight: 900;
  }
  .question-container {
    margin-top: 60px;
    .question {
      margin-bottom: 30px;
      h5 {
        color: black;
        font-size: 17px;
        font-weight: 745;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

.bottom {
  width: 100%;
  left: 0;
  bottom: 0;
  margin-left: 170px;
  margin-right: 20px;
  text-decoration: underline;
  color: black;
}

.bottom:hover {
  text-decoration: underline;
  cursor: pointer;
}
@media only screen and (max-width: 460px) {
  .faq-container {
    // margin-top: 40px;
    margin-top: 80px;
    margin-bottom: 30px;
    padding-left: 8vw;
    padding-right: 8vw;
    .question-container {
      margin-top: 20px;
      .question {
        margin-top: 2px;
      }
    }
  }
}
