@media screen and (max-width: 480px) {
  .footerHeading {
    font-size: 27px !important;
  }
  .aboutPageSlider .slick-next::before,
  .slick-prev::before {
    padding: 0px 10px 0px 10px !important;
    border-radius: 30px !important;
  }

  .aboutPageSlider .slick-prev::before {
    margin-left: 0px !important;
  }

  .aboutPageSlider .slick-next::before {
    margin-right: 0px !important;
  }

  .contactCardsAbout img {
    width: 30px;
  }

  .aboutPostCardContainer {
    width: 75% !important;
    padding: 0px !important;
    margin: 15px 0px 15px 12.5% !important;
  }

  .aboutPostHeader {
    padding: 5px !important;
  }

  .aboutPostHeaderDetails h3 {
    font-size: 13px !important;
  }

  .aboutPostHeaderDetails h3 img {
    width: 15px !important;
  }

  .aboutPostHeaderDetails p {
    font-size: 10px;
  }

  .aboutPostHeader button {
    font-size: 10px;
  }

  .aboutPostFooter {
    padding: 10px !important;
    font-size: 16px !important;
  }

  .aboutPostHeaderAvatar {
    width: 30px !important;
  }

  .stepsSection .step2,
  .stepsSection .step3 {
    margin-top: 40px !important;
  }

  .stepsSection .stepHeadNumber {
    color: black !important;
    font-family: "SuisseIntlMedium" !important;
    font-size: 60px !important;
  }

  .stepsSection .stepHeading {
    color: black !important;
    font-family: "SuisseIntlMedium" !important;
  }

  .stepsSection .stepInfo {
    color: black !important;
    font-family: "SuisseIntlMedium" !important;
  }

  .stepsSection .titlePc {
    display: none !important;
  }
  .stepsSection .titleMobile {
    display: block !important;
    font-family: "Sumana" !important;
    font-weight: 500 !important;
    color: black;
    margin: 0px 0px 0px 0px !important;
    font-size: 43px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .aboutInfoCardsSection {
    margin: 0px 0px 0px 0px !important;
    padding: 20px 0px 0px 0px !important;
  }

  .aboutInfoCards h3 {
    font-family: "SuisseIntlMedium" !important;
    color: black;
    margin: 20px 0px 20px 0px !important;
  }

  .aboutInfoCards p {
    width: 100%;
    max-width: none;
    font-family: "SuisseIntlMedium" !important;
    color: black;
    margin: auto !important;
    padding: 0px 15px 30px 10px;
  }

  .securityLogos {
    width: 90% !important;
    margin: -20px auto 20px auto !important;
  }

  .aboutPage {
    margin-top: 10vh !important;
  }

  .HeroSectionAbout .texts .subText {
    display: none !important;
  }

  .HeroSectionAbout {
    padding-top: 50px !important;
    padding-bottom: 30px !important;
  }

  .HeroSectionAbout .texts {
    margin-left: 10px;
    align-items: flex-start !important;
  }

  .HeroSectionAbout .texts div {
    font-family: Sumana !important;
    font-size: 35px !important;
    margin-bottom: 10px;
    line-height: 1.5 !important;
  }

  .HeroSectionAbout .imges {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .HeroSectionAbout .imges img {
    width: 100% !important;
  }

  .step1 .text div,
  .step2 .text div,
  .step3 .text div {
    width: auto !important;
  }

  .step1 .img img,
  .step2 .img img,
  .step3 .img img {
    width: 100% !important;
  }

  .slick-next {
    right: -0px !important;
  }

  .slick-prev {
    left: 0px !important;
  }

  .contactCardsAbout {
    height: auto !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    border-radius: 5px !important;
    font-size: 30px !important;
  }

  .contactCardsAbout h3,
  .contactCardsAbout h1 {
    margin-top: 5px !important;
    font-size: 12px !important;
    margin-bottom: 0px !important;
  }
}

.footerHeading {
}

.aboutPageSlider .slick-next::before,
.slick-prev::before {
  font-size: 35px !important;
  background: white;
  color: black !important;
  font-family: monospace !important;
  box-shadow: 0px 2px 8px 1px #00000040 !important;
  padding: 3px 15px 5px 15px;
  border-radius: 30px;
}

.aboutPageSlider .slick-prev::before {
  content: "<" !important;
  margin-left: -100px;
}

.aboutPageSlider .slick-next::before {
  content: ">" !important;
  margin-right: -100px;
}

.aboutPageSlider .slick-next {
}

.aboutPageSlider .slick-prev {
}

.aboutPostCardContainer {
  width: 93%;
  box-shadow: 0px 4px 8px 0px #00000040;
  padding: 40px;
  margin: 20px 20px 20px 20px;
}

.aboutPost {
  background: #ffffff;
  border: 1px solid #d9d9d9;
}

.aboutPostHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: auto;

  padding: 10px;
}

.aboutPostHeaderDetails h3 {
  color: black !important;
  font-size: 16px;
  font-family: "SuisseIntlMedium" !important;
  padding: 0px !important;
  margin: 0px !important;
}

.aboutPostHeaderDetails h3 img {
  display: inline;
  width: 20px;
  padding: 0px !important;
  margin: 0px !important;
}

.aboutPostHeaderDetails p {
  font-size: 13px;
  padding: 0px !important;
  margin: 0px !important;
  color: lightgray;
}

.aboutPostHeader button {
  background-color: #3097f3 !important;
  border: none !important;
  border-radius: 5px;
  color: white !important;
  padding: 5px 15px 5px 15px !important;
  font-size: 15px;
}

.aboutPostFooter {
  color: #3097f3;
  padding: 15px;
  font-size: 20px;
  font-family: "SuisseIntlMedium" !important;
}

.aboutPostBody img {
  width: 100% !important;
}

.aboutPostHeaderAvatar {
  width: 50px;
}

.stepsSection .titlePc {
  display: block;
}
.stepsSection .titleMobile {
  display: none;
}

.contactCardsAbout svg {
  font-size: 100px !important;
}

.HeroSectionAbout .texts {
  margin-left: 10px;
}

.HeroSectionAbout .texts div {
  font-size: 50px;
}

.HeroSectionAbout .texts .subText {
  font-size: 15px;
  margin-top: 20px;
}

.HeroSectionAbout .imges {
}

.myImag {
  width: 100%;
  max-width: 500px;
  object-fit: contain;
}
.stepHeadNumber {
  width: 95%;
  font-size: 3em !important;
  color: #e20909;
}
.contactCardsAbout {
  background: #2d2d2d;
  border-radius: 21.3623px;
  display: flex;
  height: 230px;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  .MuiSvgIcon-root {
    color: #fafafa !important;
    font-size: 50px;
  }
  h3 {
    color: #fafafa;
    width: 95%;
    text-align: center;
    margin: 0 auto;
  }
}
.stepHeading {
  width: 95%;
}
.stepInfo {
  width: 95%;
  font-size: 16px !important;
  color: #808080;
}
.myImagsec {
  width: 76%;
  object-fit: contain;
}
.customAboutSmallImage {
  width: 90px;
  object-fit: contain;
  margin: 0px auto;
}
.smallCardTextP {
  font-size: 16px;
  color: #808080;
  width: 100%;
  max-width: 300px;
}
@media screen and (min-width: 1024px) {
  .ownstylesImg {
    min-width: 200px;
    max-width: none !important;
    object-fit: contain;
  }
}
