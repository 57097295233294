@import "../../variables.scss";

.instant-shipping {
  width: 100%;
  text-align: center;
  font-size: 14px;

  label,
  a {
    color: rgb(33, 37, 41);
    line-height: 1;
    margin: 0;

    strong {
      margin-right: 0.25rem;
    }

    .rocket {
      transition: all 0.2s ease-in-out;
      fill: rgb(33, 37, 41);
      margin-right: 0.25rem;

      .flame {
        transition: all 0.2s ease-in-out;
      }
    }
  }

  a {
    transition: all 0.2s ease-in-out;
    &:hover {
      color: $primary;
      transition: all 0.2s ease-in-out;

      .rocket {
        transition: all 0.2s ease-in-out;
        fill: $primary;

        .flame {
          transition: all 0.2s ease-in-out;
          fill: orange;
          fill: $primary;
          animation: flame 1s infinite;
        }
      }
    }
  }
}

@keyframes flame {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.2;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    opacity: 0.5;
    fill: $primary;
  }
  40% {
    opacity: 0.3;
    fill: $primary;
  }
  50% {
    opacity: 1;
    fill: $primary;
  }
  60% {
    opacity: 0.3;
    fill: $primary;
  }
  70% {
    opacity: 0.3;
    fill: $primary;
  }
  80% {
    opacity: 1;
    fill: $primary;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
