@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
.signup-container {
  padding: 30px 0px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .form-container {
    width: 100%;
    height: 100%;

    position: relative;

    label {
      font-size: 15px;
      font-weight: 520;
      color: grey;
      position: absolute;
      z-index: 1;
      top: 20px;
      left: 12px;
      transition: 150ms ease all;
      cursor: text;
      font-family: "Raleway", sans-serif;
    }
    .float-label {
      font-size: 10px;
      top: 12px;
    }
    input {
      margin: 7px 0px;
      width: 100%;
      border: 1px solid gainsboro;
      padding: 15px 0px 12px 10px;
      border-radius: 3px;
      outline: none;
      &:focus {
        border: 1px solid rgba(237, 28, 36, 255);
      }
    }
  }
}
