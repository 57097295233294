.menus {
  margin-bottom: 0px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;

  a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
    font-size: 16px;
    font-weight: 649;
    color: rgba(0, 0, 0, 0.5);
    margin: 0px;
  }

  button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }

  .menu-items {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    padding: 8px;

    &:hover {
      background-color: #ededed;
    }

    a,
    button {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      text-align: left;
    }

    a:hover,
    button:hover {
      background-color: #f2f2f2;
      color: #242424;
    }
  }

  .dropdown {
    display: block;
    position: absolute !important;
    right: 0;
    left: -100px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px, rgba(0, 0, 0, 0.1) 0px -2px 4px;
    z-index: 9999;
    min-width: 10rem;
    list-style: none;
    background-color: white;
    display: none;

    ul::-webkit-scrollbar {
      display: none !important;
    }

    a {
      color: #242424;
      padding-inline: 8px;
    }

    .menu-items {
      position: relative;
      &:hover {
        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          background-color: red;
          width: 2px;
          height: 100%;
        }
      }
    }

    &.show {
      display: block;
    }

    .dropdown-submenu::-webkit-scrollbar {
      display: none !important;
    }
    .dropdown-submenu {
      position: absolute;
      left: 100%;
      top: 0px;
      max-height: 70vh;
      overflow-y: scroll;
      overflow-x: visible;
      overscroll-behavior: contain;

      .scroll-indicator {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        height: 10px;
        width: 100%;
        background: yellow;
        pointer-events: none;
        &:after {
          content: "";
          position: absolute;
          left: 0px;
          bottom: 0px;
          height: 40px;
          width: 100%;
          pointer-events: none;
          background-image: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 30%,
            white 70%
          );
          z-index: 1;
        }
      }
    }
  }
}
