.notification-info-section {
  max-width: 60vw;
  margin-top: 70px;

  .notification-info-content {
    p {
      font-size: 15px;
      font-weight: 575;
      color: black;
    }
  }
}
