.row {
  .infoQuestionBtn {
    font-size: 13px;
    color: white;
    background: black;
    border-radius: 10px;
    height: 20px;
    width: 20px;
    font-weight: 600;
    position: relative !important;
    top: -2px;
  }
  h1 {
    &.slidersHeading {
      font-size: 1.15rem;
    }
  }
  h5 {
    &.slidersHeading {
      font-size: 1rem;
    }
  }

  .nextButton {
    height: 100%;
    width: 100%;
    background-color: transparent !important;
    border: 0px !important;
    outline: 0px !important;
  }
  .productShowDivBrands {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 10px;
    overflow-y: hidden;
    overflow-x: auto;
    .columnProductContainer {
      // width: 300px;
      padding: 30px 20px 0px 20px;
      height: auto;
      background-color: white;
      border: 0.75946px solid #d9d9d9;
      // border-radius: 7.5946px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h5 {
        font-size: 1rem;
      }
      .cardContainers {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        height: 700px;
        overflow-y: scroll;
        scrollbar-width: 0px;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
          /* for Chrome, Safari, and Opera */
        }
        .smallCardCont {
          width: 100%;
          height: 150px;
          margin-bottom: 10px;
          img {
            width: 100%;
            height: 130px;
            object-fit: cover;
          }
          .spanText {
          }
        }
      }
    }
  }
  .productShowDivBrnadsWala {
    overflow-y: scroll !important;
    scrollbar-width: 0px !important;
    -ms-overflow-style: none !important;
    &::-webkit-scrollbar {
      display: none !important;
    }

    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    overflow-y: hidden;
    overflow-x: auto;
    .CustomcardDiv2 {
      height: 100%;
      width: 300px;
      background-color: #f4f3f1;
      cursor: pointer;

      .cardImg {
        width: 100% !important;
        object-fit: cover;
      }
      .textCont {
        width: 100%;
        margin: 10px auto;
        color: black !important;
        h6 {
          margin-left: 10px;
          font-size: 0.9rem;
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
    .productShowDiv {
      column-gap: 10px !important;
      .CustomcardDiv {
        width: 140px !important;
        height: 230px !important;
        .cardImg {
          height: 90px !important;
          margin: 0px 0px 0px 12px !important;
        }
        .textCont {
          .headingDiv {
            font-size: 0.8rem !important;
            h6 {
              font-size: 0.8rem !important;
            }
          }
          .priceDiv {
            span {
              font-size: 0.6rem !important;
            }
            h6 {
              font-size: 1rem !important;
            }
          }
          .lastSoldDiv {
            span {
              padding: 5px !important;
              font-size: 0.7rem !important;
            }
          }
        }
      }
    }
    .productShowDivBrnadsWala {
      column-gap: 5px !important;
      .CustomcardDiv2 {
        width: 140px !important;
        cursor: pointer;
        .cardImg {
          height: 90px !important;
        }
        .textCont {
          margin: 5px 0px -5px 0px !important;
          h6 {
            margin-left: 5px !important;
            font-size: 0.8rem !important;
          }
        }
      }
    }

    .productShowDivBrands {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 10px;
      overflow-y: hidden;
      overflow-x: auto;
      .columnProductContainer {
        width: 200px !important;
      }
    }
  }
  .productShowDiv {
    overflow-y: scroll !important;
    scrollbar-width: 0px !important;
    -ms-overflow-style: none !important;
    &::-webkit-scrollbar {
      display: none !important;
    }

    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 3px;
    overflow-y: hidden;
    overflow-x: auto;

    .CustomcardDiv {
      margin: 0px !important;
      padding: 0px !important;
      width: 220px;

      height: 275px;
      // margin: 0px auto;
      // width: 141px;
      background-color: #fff;
      border: 0.631049px solid #d9d9d9;
      border-radius: 0px !important;
      cursor: pointer;
      .cardImg {
        height: 130px;
        width: 85%;
        margin: 0px 0px 0px 16px;
        object-fit: cover;
      }
      .textCont {
        width: 90%;
        height: auto !important;
        margin: 10px auto;
        color: black !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        .headingDiv {
          width: 100%;
          height: auto !important;
          overflow: hidden;

          font-size: 1rem;
          h6 {
            font-size: 1rem;
          }
        }
        .priceDiv {
          width: 100%;
          span {
            font-size: 0.75rem;
          }
          h6 {
            font-size: 1.25rem;
          }
        }
        .lastSoldDiv {
          width: 100%;
          span {
            width: auto;
            padding: 7px;
            font-size: 0.75rem;
            background-color: hsl(0, 0%, 94%);
          }
        }
      }
    }
  }
}
