@import "../../variables.scss";

.shoe-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: $navbar-height;
  @media screen and (max-width: 991px) {
    margin-top: $mobile-navbar-height;
  }

  .shoe-content {
    display: flex;
    width: 100%;
    height: fit-content;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      height: 80%;
    }

    .shoe-image {
      padding: 30px;
      height: 100%;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      //background-color: yellow;
      @media screen and (max-width: 800px) {
        width: 100%;
      }
      img {
        margin-left: 30px;
        max-width: 90%;
        max-height: 660px;
        height: auto;
        width: auto;
        @media screen and (max-width: 800px) {
          margin-left: 5px;
        }
      }
    }
    .shoe-info {
      text-align: center;
      font-family: "Raleway", sans-serif;
      h1 {
        margin: 0px;
        font-size: 29px;
        font-weight: 700;
      }
      p {
        margin-bottom: 30px;
        font-size: 17px;
        font-weight: 400;
      }
    }
    .shoe-size {
      background-color: #fafafa;
      height: fit-content;
      width: 50%;
      padding: 25px;
      @media screen and (max-width: 800px) {
        width: 100%;
      }

      .control-buttons {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        //padding-left: 50px;

        .custom-button {
          margin: 10px 20px 10px 20px;
        }
      }
    }
  }
  hr {
    margin: 0px;
    color: #fafafa;
    height: 0.5px;
    @media screen and (max-width: 800px) {
      height: 0px;
    }
  }
  .button-parent {
    display: flex;
    width: 100%;
    height: fit-content;
    position: relative;
    bottom: 2px;
    //background-color: blue;
    @media screen and (max-width: 800px) {
      position: relative;
    }
    .void-div {
      width: 60%;
      @media screen and (max-width: 800px) {
        width: 0%;
        position: relative;
      }
    }
  }

  .bottomFAQ {
    right: 0;
    bottom: 0;
    margin-top: 30px;
    margin-right: 20px;
  }
}
