@media screen and (max-width: 480px) {
  .hideInMobile {
    display: none !important;
  }
  .hideInPC {
    display: block !important;
  }
}
.hideInPC {
  display: none !important;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  padding-bottom: 20px;

  &.offcanvas {
    max-height: 100vh;
    overflow-y: hidden;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 10px;
}

// Navbar
.dropdown-menu {
  max-height: 80vh !important;
  overflow-y: auto;
  overflow-x: hidden;

  .dropdown-item {
    color: white !important;

    &:hover {
      color: red !important;
      background: black;
    }
  }
}
