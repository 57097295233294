@import "../../../variables.scss";

.author {
  display: flex;
  align-items: center;
  margin: 38px 0 0;

  .author-image {
    width: 50px;
    margin: 0 13px 0 0;

    .author-image-placeholder {
      border-radius: 100%;
      overflow: hidden;
      width: 100%;
      height: 0;
      padding-top: 100%;
      position: relative;
      background-color: rgba(220, 53, 69, 0.5);

      img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center center;
      }
      svg {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);

        path {
          fill: #0006;
        }
      }
    }
  }

  .author-content {
    flex: 1;

    .author-content-name {
      display: inline-block;
      transition: 300ms;
    }

    .author-content-meta {
      opacity: 0.7;
      font-size: 0.875rem;
      line-height: 1.429;
      max-width: 450px;
    }
  }
}
