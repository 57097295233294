.header__search {
  display: flex;
  transition: all 0.25s ease-in-out;
  background: #f6f6f6;

  &.expanded {
    .form-element {
      .form-element__field {
        .header__action-search {
          right: 300px;
          transition: all 0.25s ease-in-out;
        }

        input {
          width: 300px;
          padding-right: 50px;
        }

        .header__action-exit {
          display: block;
        }

        @media only screen and (max-width: 1200px) {
          .header__action-search {
            right: 200px;
          }
          input {
            width: 200px;
          }
        }
        @media only screen and (max-width: 992px) {
          .header__action-search {
            right: 180px;
          }
          input {
            width: 180px;
          }
        }
      }
    }
  }

  .form-element {
    width: auto;
    position: relative;

    .form-element__field {
      display: block;
      width: 100%;
      position: relative;

      .header__action-search {
        position: relative;
        width: 40px;
        height: 40px;
        z-index: 100;
        display: inline-block;
        right: 0;
        transition: all 0.25s ease-in-out;

        @media only screen and (min-width: 992px) {
          width: 60px !important;
          height: 60px !important;
        }

        background: #f6f6f6 !important;
        color: black !important;
        border: 1px solid #f6f6f6 !important;

        &:hover {
          background: black !important;
          color: white !important;
        }

        &:disabled {
          background: #f6f6f6 !important;
          color: black !important;
        }
      }

      input {
        z-index: 1;
        background: #f6f6f6;
        border-radius: 0 2px 2px 0;
        border: none !important;
        outline: none !important;
        position: absolute;
        transition: 300ms;
        padding: 10px;
        top: 0;
        right: 0;
        height: 100%;
        width: 0;
      }

      .header__action-exit {
        z-index: 100;
        transition: 300ms;
        display: none;
        position: absolute;
        z-index: 2;
        bottom: 50%;
        transform: translateY(50%);
        -webkit-transform: translateY(50%);
        right: 6px;
        width: 20px;
        height: 20px;
        border: none;
      }
    }
  }
}
