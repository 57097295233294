.top-container {
  position: relative;
  padding: 20px 20px 0px 20px;
  background-color: black;
  color: white;
  z-index: 950;
}

.list-container {
  width: 100px;
  height: 80%;
  margin: 25px;
}

.trustpilot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 13px;
  padding-bottom: 15px;
  text-decoration: none;
  color: white;
}
.trustpilot img {
  max-width: 80px;
}
.trustpilot:hover {
  color: white;
}

@media (max-width: 576px) {
  .adjust-footer {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
