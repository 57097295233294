@media screen and (max-width: 991px) {
  .filterBtn_buttonContainerBtn_item {
    display: block !important;
  }
}

.filterBtn_buttonContainerBtn_item {
  display: none;
  z-index: 900;
  position: fixed;
  padding: 10px 30px;
  border-radius: 6px;
  outline: none;
  border: none;
  background: black;
  color: aliceblue;
  transform: translateX(-50%);
  left: 50%;
  bottom: 12.5%;
}
